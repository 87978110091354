import React, {useState} from 'react';
import './style.scss'
import PhoneSection from "../Home/components/PhoneSection";
import Comments from "../Home/components/Comments";
import SocialGroup from "../Home/components/SocialGroup";
import {Button, Collapse, Row} from "antd";
import {MQAText, QAText} from "./text";


const QA = () => {
    return (
        <div>
            <Row justify={"center"}
                 className={'bg-row qa-section global-font dt-nothing m-qa-row'}
                 style={{
                 }}
            >
                <p className={'global-font m-qa-title'} style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: '2.8rem'
                }}>Frequently Asked Questions</p>
                {/*{MQAText.map((txt, idx) =>*/}
                {/*    <Collapse*/}
                {/*        size={''}*/}
                {/*        collapsible="header"*/}
                {/*        items={[*/}
                {/*            {*/}
                {/*                key: idx,*/}
                {/*                label: txt[0],*/}
                {/*                children: <p className={'global-font'}>{txt[1]}</p>,*/}
                {/*            },*/}
                {/*        ]}*/}
                {/*    />*/}
                {/*)}*/}
                    <Collapse
                        size={'small'}
                        collapsible="header"
                        items={MQAText.map((txt, idx) => ({
                            key: idx,
                            label: txt[0],
                            children: <p className={'global-font'}>{txt[1]}</p>,
                        }))}
                    />

            </Row>


            <Row justify={"center"}
                 className={'bg-row qa-section global-font m-nothing'}
                 style={{
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: 'center',
                     alignItems: 'center',
                     padding:'8rem 0 16rem 0',
                 }}
            >
                <p className={'global-font'} style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: '2.8rem'
                }}>Frequently Asked Questions</p>
                {QAText.map((txt, idx) =>
                    <Collapse
                        collapsible="header"
                        items={[
                            {
                                key: idx,
                                label: txt[0],
                                children: <p className={'global-font'}>{txt[1]}</p>,
                            },
                        ]}
                    />
                )}

            </Row>
            <PhoneSection/>
            <Comments/>
            <SocialGroup/>
        </div>
    )
}

export default QA;

