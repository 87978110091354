import React, {useState} from 'react';
import {Col, Row} from "antd";
import "./style.scss"
import {FacebookFilled, InstagramFilled, LinkedinFilled, TwitterCircleFilled} from "@ant-design/icons";

const SocialGroup = () => {
    return (
        <div className={'social-section'}>
            <p className={'home-title m-title-social'}
               style={{
               }}>
                Join Our Community
            </p>
            <p className={'home-txt m-sc-sp'}
               style={{
                   height:'4vh',
                   // textAlign: "center",
                   // width: '65%',
                   // margin: "auto",
                   // marginTop: '-1%',
                   color: "white",
                   // fontWeight: "500"
               }}>
                Whether you want to share the artwork that you have created, have an opinion about AI art or want to
                stay up to date with any new features or functionality of Vieutopia, we’d love you to join the
                discussion and follow us on Twitter, Facebook, Instagram or LinkedIn.
            </p>
            <div className={'m-links-social'} style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: '1rem',
                padding: '2.6rem 0 2.6rem 0',
            }}>
                <TwitterCircleFilled
                    className={'social-icon'}
                    onClick={() => window.open('https://twitter.com/vieutopia')}
                />
                <InstagramFilled
                    className={'social-icon'}
                    onClick={() => window.open('https://www.instagram.com/vieutopia.ai/')}
                />
                <FacebookFilled
                    className={'social-icon'}
                    onClick={() => window.open('https://www.facebook.com/people/Vieutopia/100088848973732/')}
                />
                <LinkedinFilled
                    className={'social-icon'}
                    onClick={() => window.open('https://www.linkedin.com/company/vieutopia/')}
                />
            </div>
        </div>
    );
}

export default SocialGroup;