import {SERVER} from "../../../../settings";

export const api_forgot_ps = (props) => {
    const {email, newPassword} = props

    const requestOptions = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
        },
    };

// Encode email and newPassword for URL
    const encodedEmail = encodeURIComponent(email);
    const encodedNewPassword = encodeURIComponent(newPassword);

    const apiUrl = SERVER + `/users/forgot_password?email=${encodedEmail}&new_password=${encodedNewPassword}`;

    fetch(apiUrl, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            alert('Email sent. Please confirm on your mailbox.')
            window.location.href = '/login'
        })
        .catch(error => console.error('Error:', error));
}