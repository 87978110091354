import React, {useState} from 'react';
import './style.scss'
import {Button, Form, Input} from "antd";
import {api_isRegisterd} from "./service";
import RemindModal from "../../../TextToArt/components/RemindModal";

const LoginEmail = (props) => {
    const {setCurStep, setEmail} = props
    const [form] = Form.useForm();
    const [isReminderOpen, setIsReminderOpen] = useState(false)

    return (
        <div className={'lg-email-div'}
             style={{
                 display: "flex",
                 flexDirection: "column",
                 alignItems: "center",
                 justifyContent: "center",
                 paddingBottom: '0',
                 width: '35rem'
             }}>
            <p className={'global-font'}
               style={{
                   color: "white",
                   justifyContent: "center",
                   fontSize: '2.25rem',
                   fontWeight: '700',
                   marginTop: '0',
                   display:isReminderOpen?"none":"flex"
               }}>
                Welcome
            </p>
            <Form
                name="email"
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 500,
                    marginTop: '-2rem',
                    display:isReminderOpen?"none":"initial"
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
                form={form}
                onFinish={async (values) => {
                    api_isRegisterd({...values}).then(
                        async (is_reg) => {
                            if (is_reg) {
                                await setEmail(values.email)
                                await setCurStep(3)
                            } else {
                                // alert('Your email is not registered!')
                                setIsReminderOpen(true)
                            }
                        }
                    )
                }}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input className={'lg-email'}
                           placeholder={'Email'}
                    />
                </Form.Item>
                <Button className={'lg-email-ct-btn lg-password-ct-btn'}
                        htmlType={"submit"}
                        style={{
                            textAlign: "center", marginLeft: '20%',
                            height: '2rem',
                            marginTop: '3rem',
                            marginBottom: '-2rem',
                            width: '12.43781rem',
                        }}
                >
                    Continue
                </Button>
            </Form>
            <p className={'global-font'}
               style={{
                   color: "white",
                   fontWeight: '600',
                   marginBottom: '2rem',
                   display:isReminderOpen?"none":"initial"
               }}>
                Don’t have an account?&nbsp;
                <span style={{textDecoration: "underline", cursor: "pointer"}}
                      onClick={_ => setCurStep(1)}>
                Sign up
                </span>
            </p>
            <RemindModal isReminderOpen={isReminderOpen}
                         setIsReminderOpen={setIsReminderOpen}
                         text='Your email is not registered!'
            />
        </div>
    )
}

export default LoginEmail;