import React, {useState} from 'react';
import IconGroup from "./components/IconGroup";
import StyleCarousel from "./components/StyleCarousel";
import Display from "./components/Display";
import PhoneSection from "./components/PhoneSection";
import Comments from "./components/Comments";
import SocialGroup from "./components/SocialGroup";
import VideoBg from "./components/VideoBg";
import mbg from "../../img/home/Vieutopia Mobile Header Graphic New.webp"
import './style.scss'
import {HomepageMeta} from "../MetaData/HomepageMeta";
import {Button} from "antd";

const Home = () =>
    <div>
        <HomepageMeta/>
        <div className={'m-nothing'}
             style={{overflowX: "hidden"}}
        >
            <VideoBg/>
            <IconGroup/>
            <StyleCarousel/>
            <Display/>
            <PhoneSection/>
            <Comments/>
            <SocialGroup/>
        </div>
        <div className={'m-show'}>
            <img className={'m-img'} src={mbg}/>
            <Button
                className={'m-btn'}
                onClick={_ => {
                    function isMobile() {
                        return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                    }

                    function redirectToAppStore() {
                        if (isMobile()) {
                            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                                // iOS device
                                window.location.replace('https://apps.apple.com/gb/app/vieutopia-ai-art-generator/id1660264569');
                            } else if (/Android/i.test(navigator.userAgent)) {
                                // Android device
                                window.location.replace('https://play.google.com/store/apps/details?id=com.app.vieutopia&hl=en_US'); // Replace with your Android app link
                            }
                        }
                    }

                    redirectToAppStore();
                }}
            >
                <span className={'m-btn-txt'}>GET STARTED</span>
            </Button>
            <IconGroup/>
            <StyleCarousel/>
            <Display/>
            <PhoneSection/>
            <Comments/>
            <SocialGroup/>
        </div>
    </div>

export default Home;