import React, {useEffect, useState} from 'react';
import './style.scss';
import {api_get_img_by_task_id, api_get_recent_list} from './service';
import Slider from "react-slick";
import {Col, Image, Row, Spin} from "antd";
import GenImg from "../../TextToArt/components/GenImg";
import {capitalizeFirst} from "../../utils";
import t2a_img from "./Recent Text to Art Tab.webp";
import i2i_img from "./Recent Customise Image Tab.webp";


const RecentModal = (props) => {
    const [imgUrlLists, setImgUrlLists] = useState([]);
    const [process, setProcess] = useState(true)
    const slider = React.useRef(null);
    // 1 for t2a, 2 for i2i
    const [type, setType] = useState(props.type)


    useEffect(() => {
        const fetchImages = async () => {
            try {
                const taskList = await api_get_recent_list(type);
                const urls = await Promise.all(
                    taskList.map(async (task, idx) => {
                        return [await api_get_img_by_task_id(task.task_id), task.style]
                    })
                );
                await setImgUrlLists(urls);
                await setProcess(false);
            } catch (error) {
                console.error('Error fetching images:', error);
            }
        };
        type && fetchImages();
    }, [type]); // The empty dependency array ensures this effect runs only once

    const settings = {
        className: "recent-slider",
        // centerMode: true,
        infinite: true,
        // centerPadding: "4rem",
        slidesToShow: 3,
        speed: 500,
        // arrows:false,
        nextArrow:
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="73" viewBox="0 0 41 73" fill="none">
                <path
                    d="M7.37207 65.9375L31.2682 42.0413C34.0903 39.2192 34.0903 34.6012 31.2682 31.7791L7.37207 7.88281"
                    stroke="white" stroke-width="14" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>,
        prevArrow:
            <svg xmlns="http://www.w3.org/2000/svg" width="41" height="73" viewBox="0 0 41 73" fill="none">
                <path
                    d="M33.6748 65.9375L9.77869 42.0413C6.95659 39.2192 6.95659 34.6012 9.77869 31.7791L33.6748 7.88281"
                    stroke="white" stroke-width="14" stroke-miterlimit="10" stroke-linecap="round"
                    stroke-linejoin="round"/>
            </svg>
    };
    return (
        !type ?
            <div>
                <Row justify={"center"} style={{
                    columnGap: '2rem',
                    padding: '8rem 0 12rem 0'
                }}>
                    <Col span={5} className={'create-col'}
                         onClick={_ =>
                             setType(1)}
                    >
                        <img alt={'create_img'} src={t2a_img}></img>
                        <p className={'create-title global-font'}>Recent Text to Art</p>
                        <p className={'create-desc global-font'}>
                            View the results of your recent Text to Art generations and choose to run your prompt again,
                            share or download your images.
                        </p>
                    </Col>
                    <Col span={5} className={'create-col'}
                         onClick={_ => setType(2)}
                    >
                        <img alt={'i2i_image'}
                             src={i2i_img}
                            // src={coming_img}
                        />
                        <p className={'create-title global-font'}>Recent Customise Image</p>
                        <p className={'create-desc global-font'}>
                            View the results of your recent Customised Images generations and choose to generate again,
                            share or download your images.
                        </p>
                    </Col>
                    <Col span={5} className={''}>

                    </Col>
                </Row>
            </div> :
            process ?
                <Row justify={"center"} style={{
                    padding: '20rem'
                }}>
                    <Spin spinning={process} size="large"
                          tip={'Loading your arts...'}
                    />
                </Row> :
                <Slider ref={slider} {...settings}>
                    {imgUrlLists.map((img, index) => (
                        <div key={index}
                             className={'center-image-container'}
                             style={{
                                 width: '80%',
                             }}
                        >
                            <GenImg
                                className="center-image"
                                blob={img[0]}
                                imgUrl={null}
                            />
                            <p
                                className={'global-font'}
                                style={{
                                    color: 'white', textAlign: "center",
                                    fontSize: '1.25rem', fontWeight: '600'
                                }}
                            >{capitalizeFirst(img[1].toString())}</p>
                        </div>
                    ))}
                </Slider>
    );
};

export default RecentModal;
