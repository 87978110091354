import React, {useState} from 'react';
import {Col, Row} from "antd";
import {ReactComponent as Logo} from "../../../img/header/Vieutopia Logo Black.svg";
import './style.scss'

const Footer = () => {
    return (
        <div>
            <div
                className={'dt-nothing m-footer'}
            >
                <Logo style={{
                    // margin:'0 0 0 20vw',
                    width:'35vw',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                }}/>
                <p className={'m-txt-footer'}>
                    © Copyright 2023 Vieunite Limited All Rights Reserved.
                </p>
                <p className={'m-txt-footer'} style={{margin: '-15px 0 -8px 0'}}>
                    Vieutopia is brought to you by Vieunite. For enquiries please email us at info@vieutopia.com
                </p>
                <p  className={'m-txt-footer'} style={{margin: '8px 0 0 0'}}>
                <span
                    style={{textDecoration: "underline", cursor: "pointer"}}
                    onClick={() => {
                        window.open('https://vieutopia.com/terms-and-conditions.html')
                    }}>
                    Terms and Conditions
                </span>
                    &nbsp;&nbsp;&nbsp;
                    <span
                        style={{textDecoration: "underline", cursor: "pointer"}}
                        onClick={() => {
                            window.open('https://vieutopia.com/privacy-policy.html')
                        }}>
                    Privacy Policy
                </span>
                </p>
            </div>
            <div
                className={'m-nothing'}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '4rem 0 2rem 0'
                }}
            >
                <Logo></Logo>
                <p>
                    © Copyright 2023 Vieunite Limited All Rights Reserved.
                </p>
                <p style={{margin: '-15px 0 -8px 0'}}>
                    Vieutopia is brought to you by Vieunite. For enquiries please email us at info@vieutopia.com
                </p>
                <p style={{margin: '8px 0 0 0'}}>
                <span
                    style={{textDecoration: "underline", cursor: "pointer"}}
                    onClick={() => {
                        window.open('https://vieutopia.com/terms-and-conditions.html')
                    }}>
                    Terms and Conditions
                </span>
                    &nbsp;&nbsp;&nbsp;
                    <span
                        style={{textDecoration: "underline", cursor: "pointer"}}
                        onClick={() => {
                            window.open('https://vieutopia.com/privacy-policy.html')
                        }}>
                    Privacy Policy
                </span>
                </p>
            </div>
        </div>
    );
}

export default Footer;