export const commentText = [
    {
        'title': '',
        'comment': 'Out of all the AI generator apps I’ve tried this has got to be the best! It’s very quick to process new images and the images it comes out with are very unique! Would recommend anyone to use it!',
        'name': 'Sully Zee'
    },
    {
        'title': '',
        'comment': 'Imagine an AI art creator, but it’s completely free, and there’s no subscription at all! There’s no catch! This app is perfect for any artist or amateur to spark ideas, and I’ve found that it’s also great for generating wallpapers! It’s such a useful tool to have, and I highly recommend it to anyone.',
        'name': 'Hugo McCabe'
    },
    {
        'title': '',
        'comment': 'I have just tried this application and what it offers is interesting, just by placing a few keywords and choosing an art style, a beautiful wallpaper is made that you can save on your mobile phone to use. I recommend it for people who like to make their own images and have ideas at hand, since with the AI of this application it will make their work easier. A big thank you to the creator of the app and keep it up.',
        'name': 'Josue Mendoza'
    },
    // {'title': '', 'comment': '', 'name': ''},
    // {'title': '', 'comment': '', 'name': ''},
    // {'title': '', 'comment': '', 'name': ''},
    // {'title': '', 'comment': '', 'name': ''},
]