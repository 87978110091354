import React, {useState} from 'react';
import './style.scss'
import {Button, Form, Input} from "antd";
import {api_login} from "./service";
import RemindModal from "../../../TextToArt/components/RemindModal";

const LoginPassword = (props) => {
    const {setCurStep, email} = props
    const [form] = Form.useForm()
    const [isReminderOpen, setIsReminderOpen] = useState(false)
    return (
        <div className={'lg-password-div'}
             style={{
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "center",
                 alignItems: 'center',
             }}>
            <p style={{
                color: "white",
                fontSize: '1.6rem',
                margin: '5% 0 0 0'
            }}>Enter Your Password</p>
            <Form
                form={form}
                name="signupform"
                style={{
                    maxWidth: 600,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                initialValues={{
                    email: email,
                }}
                onFinish={async (values) => {
                    await api_login({...values, setIsReminderOpen})
                }}
                autoComplete="off"
            >
                <Form.Item
                    name="email"
                >
                    <Input className={'signup-form'}
                           placeholder={'Email'}
                           style={{color: 'white', zIndex: ''}}
                           disabled={true}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password
                        className={'signup-form password-signup-form '}
                        placeholder={'Password'}
                    />
                </Form.Item>
                <p style={{
                    color: "#AEAEAE",
                    margin: '-5% -35% 5% 0',
                    textAlign: "right",
                    cursor: "pointer"
                }}
                   onClick={() => setCurStep(4)}
                >Forgot password? <span style={{
                    textDecoration: "underline",
                }}>Click here</span></p>
                <Button className={'lg-password-ct-btn'}
                        htmlType={"submit"}
                >
                    Continue
                </Button>
            </Form>
            <p style={{
                color: "#AEAEAE"
            }}>
                Don’t have an account?&nbsp;
                <span style={{
                    textDecoration: "underline",
                    cursor: "pointer"
                }}
                      onClick={_ => setCurStep(1)}>
                Sign up
                </span>
            </p>
            <RemindModal isReminderOpen={isReminderOpen}
                         setIsReminderOpen={setIsReminderOpen}
                         text='Wrong user information!'
            />
        </div>
    )
}

export default LoginPassword