import {SERVER} from "../../settings";
import {capitalizeFirst} from "../utils";

export const api_renew_token = () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
        }
    }
    return fetch(SERVER + '/users/renew_token', requestOptions)
        .then(response => {
            if (response.status === 401) {
                window.location.href = '/login'
                window.alert('Sorry, you login was expired. Please click OK to re-login.')
                throw new Error('Relogin-needed')
            }
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(async result => {
            const Authorization = capitalizeFirst(result.token_type) + ' ' + result.access_token
            await localStorage.setItem('Authorization', Authorization)
        })
        .catch(error => console.error('Error:', error));
}
export const api_get_user_info = () => {
    const requestOptions = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
        },
    };

    return fetch(SERVER + '/users/', requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(error => console.error('Error:', error));
}