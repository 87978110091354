import React, {useState} from 'react';
import './style.scss'
import {Col, Divider, Row} from "antd";

const emailAddress = 'support@vieutopia.com'
const AboutModal = () => {
    return (
        <Row justify={"center"}>
            <Col className={'about-col'}
                 span={14} style={{
                display: "flex",
                flexDirection: "column",
                padding: '4rem 8rem',
                margin: '4rem auto',
            }}>
                <p className={'about-text global-font'}
                   style={{
                       fontWeight: "bold",
                       fontSize: '1.5rem'
                   }}
                >About</p>
                <div className={'divider'}/>
                <div className={'about-nav-row'}
                     onClick={_ => {
                         window.location.href = `mailto:${emailAddress}`
                     }}
                >
                    <p className={'about-text'}>Leave feedback</p>
                    <p className={'about-text'}>></p>
                </div>
                <div className={'divider'}/>
                <div className={'about-nav-row'}
                     onClick={_ => {
                         window.location.href = 'https://twitter.com/vieutopia'
                     }}
                >
                    <p className={'about-text'}>Follow us on Twitter</p>
                    <p className={'about-text'}>></p>
                </div>
                <div className={'divider'}/>
                <div className={'about-nav-row'}
                     onClick={_ => {
                         window.open('/qa', "_self")
                     }}
                >
                    <p className={'about-text'}>Tech FAQ</p>
                    <p className={'about-text'}>></p>
                </div>
                <div className={'divider'}/>
            </Col>
        </Row>
    )
}

export default AboutModal;