import React from 'react';
import './FullScreenBgVideo.css';


function FullScreenBgVideo(props) {
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };
    return (
        <section className="fullScreenVideoSection">
            <video className="fullScreenVideo" playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                <source src={props.url} type={'video/webm'}></source>
            </video>
        </section>

    );
}

export default FullScreenBgVideo;