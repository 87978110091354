export const QAText = [
    ['Why is the image I generated not related my prompt?','Our developers are exploring ways of making the input method more intuitive and hope that we can continue to optimise results accuracy and relevance.\n' +
    '\n' +
    'In the meantime, you could try choosing from one of the 24 different art styles, try to select one of the styles that is going to compliment your prompt. You can also make your prompts as detailed as possible; the smallest alteration or addition of an extra word can make a huge difference.'],
    ['Why are certain styles more accurate than others?','We are dedicated to enhancing the art styles offered on our app and striving for greater accuracy. The effectiveness of the generated effect relies heavily on whether the dataset includes your specific prompts or not. For example, if there is a limited amount of abstract art related to cats in the dataset, Vieutopia may not be able to produce a satisfactory “cat” image in an abstract art style due to the dataset’s insufficient size.\n' +
    '\n' +
    'Our developer team is working hard to enhance deep learning capabilities, and optimise accuracy of different art styles.'],
    ['Why is the effect of human figures/faces not always accurate?','We are currently working on optimising the human figures on the images generated. However, generating accurate human figures, including faces and hands, remains a difficult task, despite the extensive endeavors of advanced AI systems and the utilization of highly realistic 3D models.\n' +
    '\n' +
    'Take an example, as for questions about Why can’t AI draw realistic human hands, Eray Eliaçık answered from four perspectives, including hands are complex and there are many ways of humans perceiving hands.'],
    ['Why can’t I generate art with some prompts and get responses with “should you be searching for that?”\n' +
    '\n','As a business, we have gone to great lengths to promote a respectful and safe environment when using Vieutopia, banning text prompts that we believe may generate offensive images. If you think some of the banned words are not justified or would like to give more specific feedback, please contact us.'],
    ['Why do I get the exact same images twice?','Same images with different prompts could be due to using banned words or it might be a system bug. If you experience this, please contact us with prompts used, located under “Recent” in App Settings. We can look into this further and answer any questions you may have.\n' +
    '\n'],
    ['Why are there watermarks on generated art?\n' +
    '\n','Choosing to create images without the watermark will be an option available in future versions of the app. At the moment, we see using our logo as a reflection of how committed and confident we are to shoulder the responsibility of the image’s quality.\n' +
    '\n'],
    ['Why has Vieutopia’s loading speed recently slowed down?\n' +
    '\n','Vieutopia offers exceptional services completely free of charge, making it accessible to a vast audience. As the platform’s popularity improves, the increased demand has led to some temporary delays in loading times. However, rest assured that the Vieutopia team is actively working to address this issue.\n' +
    '\n' +
    'Committed to delivering an exceptional user experience, Vieutopia is investing in system upgrades to make loading faster and more seamless. We are dedicated to delivering a smooth and efficient browsing experience to all users, so stay tuned for an update that will make loading times faster than ever.'],
]

export const MQAText = [
    ['Prompt is not reflected in image. Why?','Our developers are exploring ways of making the input method more intuitive and hope that we can continue to optimise results accuracy and relevance.\n' +
    '\n' +
    'In the meantime, you could try choosing from one of the 24 different art styles, try to select one of the styles that is going to compliment your prompt. You can also make your prompts as detailed as possible; the smallest alteration or addition of an extra word can make a huge difference.'],
    ['Styles vary in accuracy. Why?','We are dedicated to enhancing the art styles offered on our app and striving for greater accuracy. The effectiveness of the generated effect relies heavily on whether the dataset includes your specific prompts or not. For example, if there is a limited amount of abstract art related to cats in the dataset, Vieutopia may not be able to produce a satisfactory “cat” image in an abstract art style due to the dataset’s insufficient size.\n' +
    '\n' +
    'Our developer team is working hard to enhance deep learning capabilities, and optimise accuracy of different art styles.'],
    ['Why are faces and figures inconsistent?','We are currently working on optimising the human figures on the images generated. However, generating accurate human figures, including faces and hands, remains a difficult task, despite the extensive endeavors of advanced AI systems and the utilization of highly realistic 3D models.\n' +
    '\n' +
    'Take an example, as for questions about Why can’t AI draw realistic human hands, Eray Eliaçık answered from four perspectives, including hands are complex and there are many ways of humans perceiving hands.'],
    ['Why found warnings on some prompts?\n' +
    '\n','As a business, we have gone to great lengths to promote a respectful and safe environment when using Vieutopia, banning text prompts that we believe may generate offensive images. If you think some of the banned words are not justified or would like to give more specific feedback, please contact us.'],
    ['Why do I get the same image twice?','Same images with different prompts could be due to using banned words or it might be a system bug. If you experience this, please contact us with prompts used, located under “Recent” in App Settings. We can look into this further and answer any questions you may have.\n' +
    '\n'],
    ['Why is generated art watermarked?\n' +
    '\n','Choosing to create images without the watermark will be an option available in future versions of the app. At the moment, we see using our logo as a reflection of how committed and confident we are to shoulder the responsibility of the image’s quality.\n' +
    '\n'],
    ['Why has loading speed slowed down?\n' +
    '\n','Vieutopia offers exceptional services completely free of charge, making it accessible to a vast audience. As the platform’s popularity improves, the increased demand has led to some temporary delays in loading times. However, rest assured that the Vieutopia team is actively working to address this issue.\n' +
    '\n' +
    'Committed to delivering an exceptional user experience, Vieutopia is investing in system upgrades to make loading faster and more seamless. We are dedicated to delivering a smooth and efficient browsing experience to all users, so stay tuned for an update that will make loading times faster than ever.'],
]