import React, {useEffect, useState} from 'react';
import {Col, Popover, Row, Segmented} from "antd";
import './style.scss'
import img from '../../img/app/Nebula Background Blur.webp'
import avatar from '../../img/app/avatar.jpeg'
import CreateModal from "../components/CreateModal";
import AboutModal from "../components/AboutModal";
import RecentModal from "../components/RecentModal";
import {logout} from "../utils";
import {api_get_user_info, api_renew_token} from "./service";
import AccountModal from "../components/AccountModal";

const AI = () => {
    const token = localStorage.getItem('Authorization')
    if (!token) {
        window.location.href = '/login'
    }
    const [tab, setTab] = useState('CREATE')
    const [userInfo, setUserInfo] = useState({})
    useEffect(() => {
        api_get_user_info().then((data) => {
            setUserInfo({...data})
        })
    }, []);
    useEffect(() => {
        api_renew_token().then(_ => {
        })
    })

    return (
        <div className={'bg-row'} style={{
            paddingTop: '4rem'
        }}>
            <Row
                className={'head-controller'}
                justify={"center"}
            >
                <Col span={14} style={{
                    display: "flex",
                    justifyItems: "flex-start",
                    justifyContent: "flex-start",
                }}>
                    <Col span={6} style={{
                        display: "flex",
                        flexDirection: "column", alignItems: "flex-start", justifyContent: "center"
                    }}>
                        <img src={userInfo.avatar_path ? userInfo.avatar_path : avatar}
                             alt={'avatar'}
                             style={{width: '50%', borderRadius: '50%'}}
                        />
                    </Col>
                    <Col span={12} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        marginLeft: '-10%'
                    }}>
                        <p className={'global-font'} style={{
                            color: "white",
                            fontSize: '1.7rem',
                        }}>Welcome back {userInfo.display_name}</p>
                        {/*<p>Edit your profile ></p>*/}
                        {localStorage.getItem('isGuest') ?
                            <p className={'global-font'}
                               style={{
                                   textDecoration: 'underline',
                                   cursor: "pointer",
                                   color: "white",
                                   fontSize: '1.2rem',
                                   marginTop: '-4%',
                               }}
                               onClick={_ => window.location.href = '/login'}
                            >
                                Sign up
                            </p> : <p className={'global-font'}
                                      style={{
                                          textDecoration: 'underline',
                                          cursor: "pointer",
                                          color: "white",
                                          fontSize: '1.2rem',
                                          marginTop: '-4%',
                                      }}
                                      onClick={_ => logout()}
                            >
                                Logout
                            </p>
                        }
                    </Col>
                </Col>
                <Col span={10} lg={10} xl={10} xxl={10}
                     style={{
                         display: "flex",
                         alignItems: "center",
                         justifyContent: "center",
                         // height:'4rem'
                         width: "auto"
                     }}>
                    <Segmented className={'global-font'} block
                               style={{width: '34vw'}}
                               options={['CREATE',
                                   {
                                       // label: 'RECENT',
                                       label: localStorage.getItem('isGuest') ?
                                           <Popover className={'signup-remind-popup'}
                                                    content={'Please sign up for this feature.'}>
                                               RECENT
                                           </Popover> : 'RECENT',
                                       value: 'RECENT',
                                       disabled: !!localStorage.getItem('isGuest'),
                                   },
                                   {
                                       label: localStorage.getItem('isGuest') ?
                                           <Popover className={'signup-remind-popup'}
                                                    content={'Please sign up for this feature.'}>
                                               ACCOUNT
                                           </Popover> : 'ACCOUNT',
                                       value: 'ACCOUNT',
                                       disabled: !!localStorage.getItem('isGuest'),
                                   },
                               ]}
                               onChange={(val) => setTab(val)}
                    />
                </Col>
            </Row>
            {tab === 'CREATE' ? <CreateModal/> :
                (
                    tab === 'ACCOUNT' ?
                        <AccountModal userInfo={userInfo}/> :
                        <RecentModal type={0}/>
                )
            }
        </div>
    );
}

export default AI;