import {SERVER} from "../../../settings";

export const api_upload_avatar = (bs64, setAvatarUrl) => {
    const options = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            image_encoded: bs64,
        }),
    };

    return fetch(SERVER + '/users/upload_avatar', options)
        .then(response => response.json())
        .then(data => {
            setAvatarUrl(data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

export const api_save_profile = (name, avatarUrl)=>{
    const requestOptions = {
        method: 'PUT',
        headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "display_name": name,
            "avatar_url": avatarUrl,
            // "slogan": "string"
        }),
    };

    fetch(SERVER+'/users/basic', requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            window.location.reload();
        })
        .catch(error => {
            console.error('Error:', error);
        });
}