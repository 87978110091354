import {Modal, Row, Space} from "antd";
import React, {useState} from "react";
import './style.scss'
import {DownloadOutlined, ZoomInOutlined, ZoomOutOutlined} from "@ant-design/icons";
import {ReactComponent as VieuniteIcon} from "./Vieunite.svg";


const PreviewModal = (props) => {
    let {isPreview, setIsPreview, imgUrl} = props
    const [zoom, setZoom] = useState(1); // To track the zoom level
    const onDownload = () => {
        const link = document.createElement('a');
        link.href = imgUrl;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(imgUrl);
        link.remove();
    };
    const onZoomIn = () => {
        // Increase the zoom level by a factor (e.g., 1.2 for 20% zoom in)
        setZoom(zoom * 1.2);
    };

    const onZoomOut = () => {
        // Decrease the zoom level by a factor (e.g., 0.8 for 20% zoom out)
        setZoom(zoom * 0.8);
    };
    return (
        <Modal className={'preview-div'} open={isPreview}
               onOk={_ => {
               }}
               onCancel={_ => setIsPreview(false)}
               footer={null}
        >
            <Row justify={"center"} style={{}}>
                <div className={'eye-preview-div'}>
                    {/*<p className={'eye-preview-title'}>DOWNLOAD THIS IMAGE</p>*/}
                    <img
                        src={imgUrl}
                        alt={'preview'}
                        style={{
                            width: '100%',
                            height: "100%",
                            transform: `scale(${zoom})`,
                            userSelect: "none",
                        }}
                    />
                    <Space size={16} className="eye-toolbar-wrapper">
                        <DownloadOutlined onClick={onDownload}/>
                        <ZoomOutOutlined onClick={onZoomOut}/>
                        <ZoomInOutlined onClick={onZoomIn}/>
                        <VieuniteIcon
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={_ => window.open('https://vieunite.com')}
                        />
                    </Space>
                </div>
            </Row>
        </Modal>
    )
}
export default PreviewModal;