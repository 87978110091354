import {SERVER} from "../../../settings";

export const api_get_recent_list = (type) => {
    const method = type === 1 ? 'text_to_image' : 'pix2pix'
    const requestOptions = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization')
        },
    };

    return fetch(SERVER + `/apis/recent?method=${method}`, requestOptions)
        .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP ERROR! Status: ${response.status}`);
                }
                return response.json()
            }
        )
        .then(data => {
            const taskList = data.map((dt) => {
                try {
                    const details = JSON.parse(dt.generating_detail);
                    return {
                        task_id: dt.task_id,
                        style: details['style'],
                        prompt: '',
                    }
                } catch (error) {
                    return null
                }
            }).filter(item => item !== null);
            console.log(taskList)
            return taskList
        })
        .catch(error => console.error('Error:', error));
}

export const api_get_img_by_task_id = (task_id) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            task_id: task_id,
            watermark: true,
        }),
    };
    return fetch(SERVER + '/apis/access_artwork', requestOptions)
        .then(async (response) => {
            if (!response.ok) {
                throw new Error(`HTTP ERROR! Status: ${response.status}`);
            }
            return response.blob();
        })
        .then(async blob => {
            // return URL.createObjectURL(blob)
            return blob
        })
        .catch((error) =>
            console.error('Error:', error)
        );
}