import React from 'react';
import AboutModal from "../../app/components/AboutModal";


const About = () => {
    return (
        <div style={{overflowX: "hidden", height:'70vh'}}
             className={'bg-row'}
        >
            <AboutModal/>
        </div>
    )
}

export default About;