import React, {useState} from 'react';
import {Col, Row, Segmented} from "antd";
import './style.scss'
import create_img from '../../../img/app/NEW_Text to Art Tile Image.webp'
import i2i_img from '../../../img/app/New New Customise Image.webp'
import coming_img from '../../../img/app/Image 3.webp'

const CreateModal = () => {
    return (
        <Row justify={"center"} style={{
            columnGap: '2rem',
            padding: '8rem 0 12rem 0'
        }}>
            <Col span={5} className={'create-col'}
                 onClick={_ =>
                     window.location.href = '/text-to-art'}
            >
                <img alt={'create_img'} src={create_img}></img>
                <p className={'create-title global-font'}>Text to Art</p>
                <p className={'create-desc global-font'}>
                    Enter your text prompt and explore a diverse range of art styles inspired by your favourite artists
                    and art movements.
                </p>
            </Col>
            <Col span={5} className={'create-col'}
                 onClick={_ => window.location.href = '/photo-to-art'}
            >
                <img alt={'i2i_image'}
                     src={i2i_img}
                     // src={coming_img}
                />
                <p className={'create-title global-font'}>Customise Image</p>
                <p className={'create-desc global-font'}>
                    Upload an image and modify it by changing the background, altering specific details, or fully
                    transforming it with one of our art styles.
                </p>
            </Col>
            <Col span={5} className={''}>

            </Col>
        </Row>
    )

}

export default CreateModal;