import React, {useEffect} from 'react';
import {Col, Divider, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import "./style.css";
import {Text} from "./text";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import Header from "../components/Header";
import LoginFooter from "../../app/Login/components/LoginFooter";


const Primary_Font = {
    fontFamily: "ManifoldCF-Medium",
}

const TitleFont = {
    fontSize: '42px',
    fontFamily: "ManifoldCF-Medium",
}

const mapCenter = {
    lat: 52.441596959472406,
    lng: -1.999746487113189,
};

function Contact(props) {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAcXHnSYmUPEY9e-ex-N__YbsiFWiDd1RU',
    });
    return isLoaded ? (
        <div>
            <Header/>
            <Layout>
                <Content style={{
                    background: "#D9D9D9", height: '100vh', overflowX: "hidden"
                }}>
                    <Row className={'m-top'} justify={"center"}
                         style={{margin: '6rem 0 2rem 0', background: '#D9D9D9'}} gutter={48}>
                        <Col lg={7} xs={22} style={{background: '#D9D9D9'}}>
                            <h2 className="title-app m-font"
                                style={{textAlign: "left", ...TitleFont}}>
                                <span style={{fontWeight: '500'}}>Contact</span> Vieutopia
                            </h2>
                            <p className={'contact-font'} style={{
                                width: '100%', textAlign: "left"
                            }}>
                                {Text["contact"]}
                            </p>
                            <p className={'contact-font'}
                               style={{
                                   fontWeight: '700', textAlign: "left",
                                   marginTop: '2rem'
                               }}>Vieutopia Opening Hours</p>
                            <p className={'contact-font'}
                               style={{textAlign: "left"}}>{Text['open']}</p>
                        </Col>
                        <Col className={'m-ct-contact-call'} lg={6} xs={24} style={{
                            background: "#D9D9D9"
                        }}>
                            <h2 className="title-app"
                                style={{textAlign: "left", ...TitleFont}}>
                                <span style={{fontWeight: '500'}}>Call</span> Us
                            </h2>
                            <p className={'contact-font'}
                                // style={{textAlign: "left", maxWidth:'80%',margin: '0 0 0 0' }}
                            >
                                {Text["call"]}
                            </p>
                            <button type="submit"
                                    className={"pay-button global-font"}
                                    onClick={() => {
                                        window.location.href = 'tel:03455-481311';
                                    }}
                                    style={{
                                        fontWeight: '600',
                                        padding: '0.5rem',
                                        margin: '1rem 0 1rem 0',
                                        textAlign: 'left',
                                        width: '30%',
                                        borderRadius: '1.625rem',
                                    }}
                            >
                                CALL
                            </button>
                        </Col>
                        <div className={'sim-line'} style={{margin: '0 5% 0 -3%'}}></div>
                        {/* Vertical line */}
                        <Col
                            className={'m-ct-contact'}
                            lg={6}
                            xs={{
                                span: 24,
                            }}
                            style={{background: "#D9D9D9"}}
                        >
                            <h2 className="title-app"
                                style={{textAlign: "left", ...TitleFont}}>
                                <span style={{fontWeight: '500'}}>Email</span> Us
                            </h2>
                            <p className={'contact-font'}>
                                {Text["email"].map((txt) => <p style={{...Primary_Font}}>{txt}</p>)}
                            </p>
                            <button type="submit"
                                    className={"pay-button global-font"}
                                    onClick={() => {
                                        window.location.href = 'mailto:support@vieutopia.com';
                                    }}
                                    style={{
                                        fontWeight: '600',
                                        // padding: '0.5rem',
                                        width: '30%',
                                        height: '2.6rem',
                                        borderRadius: '1.625rem',
                                        margin: '1rem 0 1rem 0',
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}
                            >
                                EMAIL
                            </button>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </div>
    ) : <></>
}

export default Contact;
