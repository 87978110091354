export const BackIcon = () => {
    return (
        <div
            style={{cursor:"pointer",
            margin:'3.2rem 0 0 0'
            }}
            onClick={_=>window.location.href='/ai-art-generator'}>
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="36" viewBox="0 0 19 36"
                 fill="none">
                <path d="M16.4556 2.49219L3.75342 15.1944C2.25332 16.6945 2.25332 19.1492 3.75342 20.6493L16.4556 33.3516"
                      stroke="white" stroke-width="4" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    )
}
