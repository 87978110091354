import React, {useState} from "react";
import {Button, Col, Form, Input, Modal, Row} from "antd";
import './style.scss'
import {api_signup} from "./service";

const text = 'To complete the sign up process please verify your account by clicking the link on the email we’ve sent you. Please check your spam folder if the email didn’t reach your inbox.'
const SignUp = (props) => {
    const {setCurStep} = props
    const [form] = Form.useForm();
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
    return (
        <div className={'signup-panel'}>
            <p style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                fontSize: '1.6rem',
                marginTop: '0',
            }}>
                Create an account
            </p>
            <Form
                name="signupform"
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                    display: isConfirmationOpen ? 'none' : "initial"
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={(values) => {
                    api_signup(values).then(() => {
                        // alert('Thanks for signing up! Please check your email!')
                        // window.location.href = '/login';
                        setIsConfirmationOpen(true)
                    }).catch(error => console.error('Error:', error));
                }}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                form={form}
            >
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                        {
                            pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: 'Please enter a valid email address!',
                        },
                    ]}
                >
                    <Input className={'signup-form'}
                           placeholder={'Email'}
                           style={{color: 'white'}}
                    />
                </Form.Item>

                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password
                        className={'signup-form password-signup-form'}
                        placeholder={'Create Password'}
                    />
                </Form.Item>
                <Form.Item
                    name="repeat_password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your password!',
                        },
                    ]}
                >
                    <Input.Password
                        className={'signup-form password-signup-form'}
                        style={{color: 'white'}}
                        placeholder={'Confirm Password'}
                    />
                </Form.Item>

                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your email!',
                        },
                    ]}
                >
                    <Input className={'signup-form'}
                           placeholder={'Name'}
                           style={{color: 'white'}}
                    />
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: 4,
                        span: 16,
                    }}
                    style={{marginTop: '4rem'}}
                >
                    <Row justify={"center"}>
                        <Button className={'signup-btn'}
                                onClick={_ => {

                                }}
                                htmlType={"submit"}
                        >
                            Create your account
                        </Button>
                    </Row>
                    <Row justify={"center"} style={{
                        paddingTop: '2rem'
                    }}>
                        <Button className={'signup-btn'}
                                onClick={_ => {
                                    setCurStep(2)
                                }}
                        >
                            I already have an account
                        </Button>
                    </Row>
                </Form.Item>
            </Form>
            <Modal className={'signup-confirmation-modal'}
                   open={isConfirmationOpen}
                   onCancel={_ => setIsConfirmationOpen(false)}
                   footer={[<Button
                       key="link"
                       type="default"
                       onClick={_ =>
                           // window.location.href = '/login'
                           setIsConfirmationOpen(false)
                       }
                       className={'limit-signup-btn'}
                   >
                       <span className={'popup-font'} style={{color: "white"}}>OK</span>
                   </Button>, <Button
                       key="link"
                       type="default"
                       onClick={_ =>
                           form.submit()
                       }
                       className={'limit-signup-btn'}
                       style={{
                           margin: '5% 0 0 -1%'
                       }}
                   >
                       <span className={'popup-font'} style={{color: "white"}}>
                           Send email again
                       </span>

                   </Button>,]}
            >
                <Col
                    span={24}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: '-3%'
                    }}
                >
                    <p className={'popup-font'} style={{
                        width: '90%',
                        textAlign: "center",
                        fontSize: '1.25rem',
                        color: "white",
                        fontWeight: '600'
                    }}>{text}</p>
                </Col>
            </Modal>
        </div>
    )
}
export default SignUp;