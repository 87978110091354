import React, {useState} from 'react';
import './style.scss'
import {Button, Col, Modal} from "antd";

const text = 'The prompts you have entered may contain restricted words, or the generated content does not comply with Vieutopia content regulations. Please check our Terms here for more information.'
const BanModal = (props) => {
    const {isBanned, setIsBanned} = props
    return (
        <Modal
            className={'limit-div'}
            open={isBanned}
            onOk={_ => {
            }}
            onCancel={_ => setIsBanned(false)}
            footer={[ <Button
                key="link"
                type="primary"
                onClick={_=>
                    window.location.href = '/terms-and-conditions.html'
                }
                className={'limit-signup-btn'}
                style={{
                    width:'16vw'
                }}
            >
                <span className={'popup-font'} style={{
                    fontWeight:600,
                    fontSize:'1vw',
                    width:'30vw'
                }}>Check Terms&Conditions</span>
            </Button>,]}
        >
            <Col
                span={24}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <p className={'popup-font'} style={{
                    width:'80%',
                    textAlign:"center",
                    fontSize:'1.5rem',
                    color:"black",
                    fontWeight:'600'
                }}>{text}</p>
            </Col>
        </Modal>
    )
}

export default BanModal