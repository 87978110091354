import React, {useEffect, useState} from 'react';
import './style.scss'
import {Button, Col, Form, Image, Input, message, Modal, Radio, Row, Space, Spin} from "antd";
import {Upload} from "antd";
import {i2iStyles, I2ITitle, LandingText} from "./text";
import StyleModal from "../TextToArt/components/StyleModal";
import {BackIcon} from "../utils/icon";
import LimitModal from "../TextToArt/components/LimitModal";
import tipsImg from "../../img/app/i2i/Window 150dpi.webp"
import {EyeOutlined, PlusOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import SplitView from "./components/SplitView";
import {api_generate_image, api_upload_image, i2iPollForResult} from "./service";
import PreviewModal from "./components/PreviewModal";
import {api_renew_token} from "../AI/service";

const ImgToImg = () => {
    const token = localStorage.getItem('Authorization')
    if (!token) {
        window.location.href = '/login'
    }
    useEffect(() => {
        api_renew_token().then(_ => {
        })
    })
    const [isStyleOpen, setIsStyleOpen] = useState(false);
    const [form] = Form.useForm()
    const [imgUrl, setImgUrl] = useState(null)
    const [ogImgUrl, setOgImgUrl] = useState(null)
    const [imgId, setImgId] = useState(null)
    const [process, setProcess] = useState(false)
    const [isLimited, setIsLimited] = useState(false)
    const [blob, setBlob] = useState('')
    const [isPreview, setIsPreview] = useState(false)
    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    // Extract the Base64-encoded image data
                    const base64String = e.target.result.split(',')[1];
                    resolve(base64String);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsDataURL(file);
            } else {
                reject(new Error("File not provided"));
            }
        });
    };
    const uploadReq = async ({file}) => {
        try {
            await setOgImgUrl(URL.createObjectURL(file))
            const bs64 = await getBase64(file);
            await api_upload_image(bs64).then((image_id) => {
                setImgId(image_id)
            })
            await setImgUrl(null)
            // await api_upload_avatar(bs64, setAvatarUrl)
        } catch (error) {
            console.error("Error converting image to Base64:", error);
        }
    }
    return (
        <div className={'t2a'}>
            <Row
                className={'bg-row t2a-row'}
                justify={"center"}
            >
                <BackIcon/>
                <Col span={10} xl={10} lg={10} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pointerEvents: process ? "none" : "initial"
                }}>
                    <p className={'global-font'} style={{
                        color: "white",
                        fontSize: '2.6rem',
                        textAlign: "center"
                    }}>
                        Image Customisation
                    </p>
                    <p className={'global-font'} style={{
                        color: "white",
                        textAlign: "center",
                        width: '80%',
                        margin: '-2% 0 4% 0',
                        fontSize: '1rem'
                    }}>
                        {I2ITitle}
                    </p>
                    <Form
                        className={'t2a-input'}
                        form={form}
                        initialValues={{quality: 'MD', ratio: '1024 x 576'}}
                        onFinish={async (values) => {
                            const genImgProps = {
                                "user_request": {
                                    "device_type": "web",
                                    "firebase_token": "string"
                                },
                                "pix2pix_generate_image_item": {
                                    "prompt": "",
                                    "style": i2iStyles[values.style],
                                    "image_id": imgId,
                                    "negative_prompt": "string",
                                    // "guidance_scale": 7.5,
                                    "num_inference_steps": 10,
                                    "image_guidance_scale": 1,
                                    "minitopia": false
                                }
                            }
                            await api_generate_image(genImgProps, setProcess, setIsLimited).then((taskId) => {
                                setTimeout(() => {
                                    i2iPollForResult({
                                        taskId: taskId,
                                        setImgUrl: setImgUrl,
                                        setProcess: setProcess,
                                        setBlob: setBlob
                                    });
                                }, 5000);
                            })
                        }}
                    >
                        <Form.Item
                            name='prompt'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your prompt!',
                                },
                            ]}
                        >
                            {ogImgUrl ? <img className={'up-img'} src={ogImgUrl}/> :
                                <Dragger className={'i2i-uploader'}
                                         accept={'.png,.jpg,.jpeg,.webp'}
                                         showUploadList={false}
                                         maxCount={1}
                                         multiple={false}
                                         customRequest={uploadReq}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <PlusOutlined style={{color: "grey", fontSize: '3vw'}}/>
                                    </p>
                                    <p className="" style={{color: "white", fontWeight: '600'}}>
                                        Drag and drop or click here
                                    </p>
                                    <p className="" style={{color: "white", fontWeight: '600'}}>
                                        to upload your artwork
                                    </p>
                                </Dragger>
                            }

                        </Form.Item>
                        <Form.Item
                            style={{marginTop: '-6%'}}
                            name='style'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose a style!',
                                },
                            ]}
                            onClick={_ => setIsStyleOpen(true)}
                        >
                            <Input className={'style-input'}
                                   placeholder="Choose a style"
                                   readOnly
                            />
                        </Form.Item>
                        <Button
                            style={{marginTop: imgId ? '-2rem' : '0'}}
                            className={'t2a-btn'} htmlType={"submit"}>
                            CREATE
                        </Button>
                        <Upload
                            style={{
                                display: imgId ? "initial" : "none",
                            }}
                            accept={'.png,.jpg,.jpeg,.webp'}
                            showUploadList={false}
                            maxCount={1}
                            multiple={false}
                            customRequest={uploadReq}
                        >
                            <Button
                                style={{
                                    margin: '-2rem 0 -18rem 0',
                                    display: imgId ? "initial" : "none"
                                }}
                                className={'t2a-btn'}
                            >
                                <p style={{marginTop:'-1.3vh'}}>UPLOAD NEW IMAGE</p>
                            </Button>
                        </Upload>
                    </Form>
                </Col>
                <Col span={10} xl={10} lg={10} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: '5%',
                    position: "relative"
                }}>
                    <Image
                        style={{
                            margin: '-50% 0 0 0%', width: '45vw',
                            userSelect: "none",
                            display: imgUrl ? "none" : "initial"
                        }}
                        src={tipsImg}
                        preview={false}
                    />

                    {process ?
                        <Spin
                            spinning={process}
                            tip={'GENERATING...'}
                            size="large"
                            style={{marginTop: '8rem'}}
                        >
                            <div></div>
                        </Spin>
                        :
                        imgUrl === null ?
                            !isStyleOpen ?
                                <p className={'land-txt'}>
                                    {LandingText}
                                </p> :
                                <div className={'land-txt'}></div> :
                            <SplitView img1={ogImgUrl} img2={imgUrl} setIsPreview={setIsPreview}/>
                    }
                    {/*<EyeOutlined*/}
                    {/*    className={'eye-preview'}*/}
                    {/*    style={{display: imgUrl && !process ? "initial" : "none"}}*/}
                    {/*    onClick={() => {*/}
                    {/*        setIsPreview(true)*/}
                    {/*    }}*/}
                    {/*/>*/}
                </Col>
            </Row>
            <StyleModal
                isStyleOpen={isStyleOpen}
                setIsStyleOpen={setIsStyleOpen}
                form={form}
                mode={'i2i'}
                isI2I={true}
            />
            <LimitModal
                isLimited={isLimited}
                setIsLimited={setIsLimited}
            />
            <PreviewModal
                isPreview={isPreview}
                setIsPreview={setIsPreview}
                imgUrl={imgUrl}
            />
        </div>
    )
}

export default ImgToImg;