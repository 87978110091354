import React, {useEffect, useState} from 'react';
import './style.scss'
import {Button, Col, Form, Image, Input, Modal, Radio, Row, Spin} from "antd";
import {RatioLists, T2ATitle} from "./text";
import TextArea from "antd/es/input/TextArea";
import StyleModal from "./components/StyleModal";
import {api_gen_img, pollForResult} from "./service";
import GenImg from "./components/GenImg";
import defaultImg from "../../img/app/RatioImgs/LD Horizontal 16-9_1024 x 576.png"
import LimitModal from "./components/LimitModal";
import {BackIcon} from "../utils/icon";

import LD1 from "../../img/app/RatioImgs/LD Horizontal 16-9_1024 x 576.png"
import LD2 from "../../img/app/RatioImgs/LD Horizontal 4-3_786 x 576.png"
import LD3 from "../../img/app/RatioImgs/LD Horizontal 5-4_720 x 576.png"
import LD4 from "../../img/app/RatioImgs/LD_576 x 576.png"
import LD5 from "../../img/app/RatioImgs/LD Vertical 9-16_576 x 1024.png"
import LD6 from "../../img/app/RatioImgs/LD Vertical 3-4_576 x 786.png"
import LD7 from "../../img/app/RatioImgs/LD Vertical 4-5_576 x 720.png"
import MD1 from "../../img/app/RatioImgs/MD Horizontal 16-9_1280 x 720.png"
import MD2 from "../../img/app/RatioImgs/MD Horizontal 4-3_960 x 720.png"
import MD3 from "../../img/app/RatioImgs/MD Horizontal 5-4_900 x 720.png"
import MD4 from "../../img/app/RatioImgs/MD 720 x 720.png"
import MD5 from "../../img/app/RatioImgs/MD Vertical 9-16_720 x 1280.png"
import MD6 from "../../img/app/RatioImgs/MD Vertical 3-4_720 x 960.png"
import MD7 from "../../img/app/RatioImgs/MD Vertical 4-5_720 x 900.png"
import HD1 from "../../img/app/RatioImgs/HD Horizontal 16-9_1920 x 1080.png"
import HD2 from "../../img/app/RatioImgs/HD Horizontal 4-3_1440 x 1080.png"
import HD3 from "../../img/app/RatioImgs/HD Horizontal 5-4_1350 x 1080.png"
import HD4 from "../../img/app/RatioImgs/HD 1080 x 1080.png"
import HD5 from "../../img/app/RatioImgs/HD Vertical 9-16_1080 x 1920.png"
import HD6 from "../../img/app/RatioImgs/HD Vertical 3-4_1080 x 1440.png"
import HD7 from "../../img/app/RatioImgs/HD Vertical 4-5_1080 x 1350.png"
import {api_renew_token} from "../AI/service";
import BanModal from "./components/BanModal";

const RatioImgMap = {
    '1920 x 1080': HD1,
    '1440 x 1080': HD2,
    '1350 x 1080': HD3,
    '1080 x 1080': HD4,
    '1080 x 1920': HD5,
    '1080 x 1440': HD6,
    '1080 x 1350': HD7,
    '1024 x 576': LD1,
    '768 x 576': LD2,
    '720 x 576': LD3,
    '576 x 576': LD4,
    '576 x 1024': LD5,
    '576 x 768': LD6,
    '576 x 720': LD7,
    '1280 x 720': MD1,
    '960 x 720': MD2,
    '900 x 720': MD3,
    '720 x 720': MD4,
    '720 x 1280': MD5,
    '720 x 960': MD6,
    '720 x 900': MD7,
}


const TextToArt = () => {
    const token = localStorage.getItem('Authorization')
    if (!token) {
        window.location.href = '/login'
    }
    const [isStyleOpen, setIsStyleOpen] = useState(false);
    const [form] = Form.useForm()
    const [quality, setQuality] = useState('LD')
    const [ratioList, setRatioList] = useState(RatioLists['LD'])
    const [imgUrl, setImgUrl] = useState(defaultImg)
    const [process, setProcess] = useState(false)
    const [isLimited, setIsLimited] = useState(false)
    const [isBanned, setIsBanned] = useState(false)
    const [blob, setBlob] = useState('')
    useEffect(() => {
        api_renew_token().then(_ => {
        })
    })
    return (
        <div className={'t2a'}>
            <Row
                className={'bg-row t2a-row'}
                justify={"center"}
            >
                <BackIcon/>
                <Col span={10} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    pointerEvents: process ? "none" : "initial"
                }}>
                    <p className={'global-font'} style={{
                        color: "white",
                        fontSize: '2.6rem',
                        textAlign: "center"
                    }}>
                        Text to Art
                    </p>
                    <p className={'global-font'} style={{
                        color: "white",
                        textAlign: "center",
                        width: '80%',
                        margin: '-2% 0 4% 0',
                        fontSize: '1rem'
                    }}>
                        {T2ATitle}
                    </p>
                    <Form
                        className={'t2a-input'}
                        form={form}
                        initialValues={{quality: 'MD', ratio: '1024 x 576'}}
                        onFinish={async (values) => {
                            const genImgProps = {
                                style: values.style,
                                prompt: values.prompt,
                                width: values.ratio.split('x')[0],
                                height: values.ratio.split('x')[1],
                                setProcess: setProcess,
                                setIsLimited: setIsLimited,
                                setIsBanned: setIsBanned
                            }
                            await api_gen_img(genImgProps).then((taskId) => {
                                setTimeout(() => {
                                    pollForResult({
                                        taskId: taskId,
                                        setImgUrl: setImgUrl,
                                        setProcess: setProcess,
                                        setBlob: setBlob
                                    });
                                }, 5000);
                            })
                        }}
                    >
                        <Form.Item
                            name='prompt'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your prompt!',
                                },
                                {
                                    max: 250,
                                    message: 'Text prompt cannot exceed 250 characters!',
                                },
                            ]}
                        >
                            <TextArea className={'prompt-input'}
                                      rows={6}
                                      placeholder="Text prompt"
                            />
                        </Form.Item>
                        <Form.Item
                            style={{marginTop: '-6%'}}
                            name='style'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please choose a style!',
                                },
                            ]}
                            onClick={_ => setIsStyleOpen(true)}
                        >
                            <Input className={'style-input'}
                                   placeholder="Choose a style"
                                   readOnly
                            />
                        </Form.Item>
                        <p style={{
                            color: "white",
                            margin: '-8% 0 -8% -83%'
                        }}>
                            QUALITY
                        </p>
                        <Form.Item style={{}}
                                   name='quality'
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please choose an quality!',
                                       },
                                   ]}
                        >
                            <Row justify={"start"} style={{
                                display: "flex"
                            }}>
                                <Radio.Group
                                    onChange={(e) => {
                                        setQuality(e.target.value)
                                        setRatioList(RatioLists[e.target.value])
                                    }}
                                    value={quality}
                                    style={{
                                        minWidth: '300%',
                                        marginLeft: '-105%',
                                        display: "flex",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <Col span={8}>
                                        <Radio value={'LD'} className={'ratio-choice'}>LD</Radio>
                                    </Col>
                                    <Col span={8}>
                                        <Radio value={'MD'} className={'ratio-choice'}>MD</Radio>
                                    </Col>
                                    <Col span={8}>
                                        <Radio value={'HD'} className={'ratio-choice'}>HD</Radio>
                                    </Col>
                                    {/*<Radio value={'LD'} className={'ratio-choice'}>LD</Radio>*/}
                                    {/*<Radio value={'MD'} className={'ratio-choice'}>MD</Radio>*/}
                                    {/*<Radio value={'HD'} className={'ratio-choice'}>HD</Radio>*/}
                                    {/*<Radio value={'UHD'} className={'ratio-choice'}>UHD</Radio>*/}
                                </Radio.Group>
                            </Row>

                        </Form.Item>

                        <p style={{
                            color: "white",
                            margin: '-10% 0 -6% -85%'
                        }}>RATIO</p>
                        <Form.Item
                            name='ratio'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please pick an ratio!',
                                },
                            ]}
                        >
                            <Row>
                                <Radio.Group
                                    // style={{marginLeft:'1%'}}
                                    onChange={(e) => {
                                        setImgUrl(RatioImgMap[e.target.value])
                                    }}
                                >
                                    <Radio value={ratioList[0]}
                                           className={'ratio-choice'}>{`16:9 | ${ratioList[0]}`}</Radio>
                                    <Radio value={ratioList[1]}
                                           className={'ratio-choice'}>{`4:3 | ${ratioList[1]}`}</Radio>
                                    <Radio value={ratioList[2]}
                                           className={'ratio-choice'}>{`5:4 | ${ratioList[2]}`}</Radio>
                                    <Radio value={ratioList[3]}
                                           className={'ratio-choice'}>{`1:1 | ${ratioList[3]}`}</Radio>
                                    <Row style={{marginTop: '4%'}}>
                                        <Radio value={ratioList[4]}
                                               className={'ratio-choice'}>{`9:16 | ${ratioList[4]}`}</Radio>
                                        <Radio value={ratioList[5]}
                                               className={'ratio-choice'}>{`3:4 | ${ratioList[5]}`}</Radio>
                                        <Radio value={ratioList[6]}
                                               className={'ratio-choice'}>{`4:5 | ${ratioList[6]}`}</Radio>
                                    </Row>
                                    {/*<Radio value={ratioList[4]} className={'ratio-choice'}>{`9:16 | ${ratioList[4]}`}</Radio>*/}
                                    {/*<Radio value={ratioList[5]} className={'ratio-choice'}>{`3:4 | ${ratioList[5]}`}</Radio>*/}
                                    {/*<Radio value={ratioList[6]} className={'ratio-choice'}>{`4:5 | ${ratioList[6]}`}</Radio>*/}
                                </Radio.Group>
                            </Row>


                        </Form.Item>
                        <Button
                            style={{marginTop: '4rem'}}
                            className={'t2a-btn'} htmlType={"submit"}>
                            CONTINUE
                        </Button>
                    </Form>
                </Col>
                <Col span={10} style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: '5%'
                }}>
                    {process ? <Spin spinning={process} size="large"/> :
                        <GenImg
                            blob={blob}
                            imgUrl={imgUrl}
                        />}
                </Col>
            </Row>
            <StyleModal
                isStyleOpen={isStyleOpen}
                setIsStyleOpen={setIsStyleOpen}
                form={form}
            />
            <LimitModal
                isLimited={isLimited}
                setIsLimited={setIsLimited}
            />
            <BanModal
                isBanned={isBanned}
                setIsBanned={setIsBanned}
            />
        </div>
    )
}

export default TextToArt;