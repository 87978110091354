import {SERVER} from "../../../../settings";

export const api_signup = (props) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "email": props.email,
            "password": props.password,
            "display_name": props.username
        }),
    };

    return fetch(SERVER + '/users/register', requestOptions)
        .then(response => {
            if (!response.ok) {
                if (response.status === 400) {
                    alert(`Email registered already!`); // Display the response body text in an alert
                    // window.location.href = '/login';
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => console.log(data))
}