// export const I2ITitle = "Enter your text prompt and choose from a diverse range of art styles developed with the characteristics of your favourite artists and art movement"
export const I2ITitle = 'Upload an image from your gallery and fully transform it with one of our available art styles'
export const LandingText = "Please upload a picture from the left operation panel and choose your preferred style to start the generation process."

export const i2iStyles = {
    "Picasso": "Picasso",
    "Da Vinci": "Da Vinci",
    "Dali": "Dali",
    "Shanshui": "shanshui",
    "Fantasy": "fantasy",
    "Botticelli": "Botticelli",
    "Van Gogh": "Van Gogh",
    "Snowy": "snowy",
    "Monet": "Monet",
    "Cyberpunk": "cyberpunk",
    "Kind of Blue": "kindofblue",
    "Sketch": "sketch",
    "Watercolour": "watercolour",
    "Rainy": "rainy",
}

export const i2iOrder = Object.keys(i2iStyles).reduce(
    (order, styleKey, index) => (
        {...order, [styleKey]: index}), {}
);
