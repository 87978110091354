import React, {useState} from 'react';
import {Row} from "antd";

const LoginFooter = () => {
    return (
        <Row justify={"center"}
             style={{
                 position: 'fixed',
                 bottom: 0,
                 left: 0,
                 width: '100%',
                 padding: '10px',
                 backgroundColor: 'rgba(0, 0, 0, 0.5)',
                 color: 'white',
                 textAlign: 'center',
             }}
        >
            <p>
                <span className={'global-font'}
                    style={{
                        textDecoration: "underline", cursor: "pointer",
                        color: "#DADADA"
                    }}
                    onClick={() => {
                        window.open('https://vieutopia.com/terms-and-conditions.html')
                    }}>
                    Terms and Conditions
                </span>
                &nbsp;&nbsp;&nbsp;
                <span className={'global-font'}
                    style={{
                        textDecoration: "underline", cursor: "pointer",
                        color: "#DADADA"
                    }}
                    onClick={() => {
                        window.open('https://vieutopia.com/privacy-policy.html')
                    }}>
                    Privacy Policy
                </span>
            </p>
        </Row>
    )
}

export default LoginFooter;