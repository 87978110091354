import React, {useState} from 'react';
import './MobileMenu.scss';
import {ReactComponent as Logo} from "../../../img/header/Vieutopia Logo Black.svg";
import {Divider} from "antd";
import {useNavigate} from "react-router-dom";

export default function MobileMenu() {
    return (
        <div style={{overflowX:"hidden"}}>
            <h1>
                <a href="/home" className={'logo-txt'}>
                    {/*<Logo style={{*/}
                    {/*filter: 'invert(100%)',*/}
                    {/*margin: '0 0 0 0',*/}
                    {/*width: '35vw'}}/>*/}
                    Vieutopia.
                </a>
            </h1>

            <input id="burger" type="checkbox"/>

            <label htmlFor="burger">
                <span></span>
                <span></span>
                <span></span>
            </label>
            <nav>
                <ul>
                    <li><a href="/qa">TECH FAQ</a><Divider className={'m-menu-divider'}/></li>
                    <li><a href="https://twitter.com/vieutopia">FOLLOW US ON TWITTER</a><Divider className={'m-menu-divider'}/></li>
                    <li><a href="/contact">CONTACT US</a><Divider className={'m-menu-divider'}/></li>
                </ul>
            </nav>
        </div>
    )
}