import React, {useState} from 'react';
import {Col, Row} from "antd";
import Header from "../../pages/components/Header";
import Footer from "../../pages/components/Footer";
import {Outlet} from "react-router-dom";
import './style.scss'

const BasicLayout = () => {
    return (
        <>
            <Header/>
            <Outlet/>
            <Footer/>
        </>
    );
}

export default BasicLayout;