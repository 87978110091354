import React, {useState} from 'react';
import './style.scss'
import {Col, Row} from "antd";
import {ReactComponent as Icon_1} from "../../../../img/home/icons/Free to Use Icon.svg"
import {ReactComponent as Icon_2} from "../../../../img/home/icons/Web & Mobile Icon.svg"
import {ReactComponent as Icon_3} from "../../../../img/home/icons/No Daily Limits Icon.svg"
import {ReactComponent as Icon_4} from "../../../../img/home/icons/Multiple Art Styles Icon.svg"
import {ReactComponent as Icon_5} from "../../../../img/home/icons/Safe Results Icon.svg"
import {ReactComponent as Icon_6} from "../../../../img/home/icons/Showroom Icon.svg"
import {MText, Text} from "./text";

const Icons = [<Icon_1/>, <Icon_2/>, <Icon_3/>, <Icon_4/>, <Icon_5/>, <Icon_6/>]
const SingleIcon = props =>
    <Col lg={6} xs={0}>
        <p className={'global-font'}
           style={{
               color: "white",
               textAlign: "center",
               fontSize: '1.125rem',
               fontWeight: '700'
           }}>
            {props.title}
        </p>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            {React.cloneElement(props.icon, {
                style: {width: '30%', height: 'auto'} // Adjust the dimensions as needed
            })}
        </div>
        <p className={'home-txt'}
           style={{
               color: "white",
               // textAlign: "center",
               // width: '60%',
               // margin: "auto",
               // fontSize: '1rem',
               // fontWeight: '500',
           }}>
            {Text[props.id]}
        </p>
    </Col>

const MSingleIcon = props =>
    <Col lg={0} xs={8}>
        <p className={'global-font m-icon-group-titles'}
           style={{
               textAlign: "center",
           }}>
            {props.title}
        </p>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            {React.cloneElement(props.icon, {
                style: {width: '50%', height: 'auto'} // Adjust the dimensions as needed
            })}
        </div>
        <p className={'home-txt'}
           style={{
               color: "white",
               textAlign: "center",
               width: '105%',
               margin: "auto",
               fontSize: '1rem',
               fontWeight: '500',
               lineHeight: '132%'
           }}>
            {MText[props.id]}
        </p>
    </Col>
const IconGroup = () => {
    return (
        <div>
            <div className={'dt-nothing'} style={{
                backgroundColor: "black",
            }}>
                <Row justify={"center"} style={{
                    columnGap: '4rem', background: 'black',
                    margin: '-1.6rem 0 0 0',
                }}>
                    <p className={'global-font m-title-what'}
                       style={{
                       }}
                    >
                        What makes Vieutopia different?
                    </p>
                </Row>
                <Row justify={"center"} style={{columnGap: '4rem',
                    background: 'black',
                    margin:'-0.1rem 0 0 0',
                }}>
                    {['FREE TO USE', 'WEB & MOBILE VERSION',]
                        .map((title, idx) =>
                            <MSingleIcon icon={Icons[idx]} title={title} id={idx} key={idx}/>
                        )
                    }
                </Row>
                <Row justify={"center"} style={{columnGap: '4rem',
                    background: 'black',
                    margin:'-12rem 0 0 0',
                }}>
                    {['MULTIPLE ART STYLES', 'NO DAILY LIMITS',]
                        .map((title, idx) =>
                            <MSingleIcon icon={Icons[idx + 2]} title={title} id={idx + 2} key={idx + 2}/>
                        )
                    }
                </Row>
                <Row justify={"center"} style={{
                    columnGap: '4rem',
                    padding: '0rem 0 0rem 0',
                    background: 'black',
                    margin: '-12rem 0 0 0',
                    height:'20rem'
                }}>
                    {['SAFE RESULTS', 'SHOWROOM']
                        .map((title, idx) =>
                            <MSingleIcon icon={Icons[idx + 4]} title={title} id={idx + 4} key={idx + 4}/>
                        )
                    }
                </Row>
            </div>
            {/*// Desktop version*/}
            <div className={'m-nothing'} style={{backgroundColor: "black", marginTop: '-1.6rem'}}>
                <Row justify={"center"}>
                    <p className={'global-font'}
                       style={{color: "white", fontWeight: 'bold', fontSize: '2.25rem'}}>
                        What makes Vieutopia different?</p>
                </Row>
                <Row justify={"center"} style={{columnGap: '6rem'}}>
                    {['FREE TO USE', 'WEB & MOBILE VERSION', 'NO DAILY LIMITS']
                        .map((title, idx) =>
                            <SingleIcon icon={Icons[idx]} title={title} id={idx} key={idx}/>
                        )
                    }
                </Row>
                <Row justify={"center"} style={{columnGap: '6rem', padding: '6rem 0 6rem 0'}}>
                    {['MULTIPLE ART STYLES', 'SAFE RESULTS', 'SHOWROOM']
                        .map((title, idx) =>
                            <SingleIcon icon={Icons[idx + 3]} title={title} id={idx + 3} key={idx + 3}/>
                        )
                    }
                </Row>
            </div>
        </div>
    );
}

export default IconGroup;