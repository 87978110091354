import React, {useEffect, useState} from 'react';
import './style.scss'
import {Image, Space} from "antd";
import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined, ShareAltOutlined,
    SwapOutlined, ZoomInOutlined,
    ZoomOutOutlined
} from "@ant-design/icons";
import {ReactComponent as VieuniteIcon} from "../../../ImgToImg/components/PreviewModal/Vieunite.svg"


import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
} from 'react-share';

const GenImg = (props) => {
    let {blob, imgUrl} = props
    if (imgUrl === null) {
        imgUrl = URL.createObjectURL(blob)
    }
    const onDownload = () => {
        const link = document.createElement('a');
        link.href = imgUrl;
        link.download = 'image.png';
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(imgUrl);
        link.remove();
    };

    const onShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    files: [new File([blob], 'image.png')],
                    title: 'Share this image',
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.error('Web Share API is not supported on this device.');
        }
    };

    return (
        <Image src={imgUrl}
               className={'t2a-gen-img'}
               preview={{
                   toolbarRender: (
                       _,
                       {
                           transform: {scale},
                           actions: {onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn},
                       },
                   ) => (
                       <Space size={16} className="toolbar-wrapper">
                           {/*<ShareAltOutlined onClick={onShare}/> /!* Add the Share button *!/*/}
                           <DownloadOutlined onClick={onDownload}/>
                           {/*<SwapOutlined rotate={90} onClick={onFlipY}/>*/}
                           {/*<SwapOutlined onClick={onFlipX}/>*/}
                           {/*<RotateLeftOutlined onClick={onRotateLeft}/>*/}
                           {/*<RotateRightOutlined onClick={onRotateRight}/>*/}
                           <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut}/>
                           <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn}/>
                           <VieuniteIcon
                               style={{
                                   cursor: "pointer"
                               }}
                               onClick={_ => window.open('https://vieunite.com')}
                           />
                           {/*<div className="share-buttons">*/}
                           {/*    /!* Share buttons *!/*/}
                           {/*    <FacebookShareButton url={imgUrl}>*/}
                           {/*        <FacebookIcon size={32} round />*/}
                           {/*    </FacebookShareButton>*/}
                           {/*    <TwitterShareButton url={imgUrl}>*/}
                           {/*        <TwitterIcon size={32} round />*/}
                           {/*    </TwitterShareButton>*/}
                           {/*    <WhatsappShareButton url={imgUrl}>*/}
                           {/*        <WhatsappIcon size={32} round />*/}
                           {/*    </WhatsappShareButton>*/}
                           {/*</div>*/}
                       </Space>
                   ),
               }}
        />
    )
}

export default GenImg;