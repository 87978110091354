import React, {useState} from 'react';
import {Button, Col, Row} from "antd";
import img_phone from "../../../../img/app/phoneimg.webp"
import qr from "../../../../img/home/img.png"
import {AppleOutlined, GoogleOutlined} from "@ant-design/icons";
import './style.scss'
import dt_img from './Vieutopia Laptop Home Screen.webp'
const PhoneSection = () => {
    return (
        <Row justify={"center"} style={{
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            padding:'2rem 0 4rem 0'
        }}>
            {/*Mobile UI*/}
            <Col className={'dt-nothing'} xs={24}>
                <p className={'m-sm-title'}
                   style={{
                       width:'50%',
                       margin:'0 0 0 4vw'
                   }}>
                    Sync Your Creations Across Your Devices
                </p>
                <p className={'home-txt'}
                   style={{
                   }}>
                    Create your aesthetic - with an optimised aspect ratio, your creations can be turned into unique wallpapers for your computer. Whatever your style, you’ll be able to personalise your device and give new life to your screen! Visit <span style={{fontWeight:"bold"}}>www.vieutopia.com</span> to access the desktop version.
                </p>
                <img src={dt_img} alt={'dt-phone'}
                     className={'laptop-img'}/>
            </Col>

            {/*Desktop UI*/}
            <Col className={'m-nothing'} lg={6} style={{}}>
                <p className={'home-title '}
                   style={{
                       textAlign: "left", width: '150%',
                       fontWeight: "bold",
                       // fontSize: '1.8vw',
                       fontSize: '2.25rem',
                       margin: "2rem auto 2rem auto",
                       marginLeft: '17%',
                   }}>
                    Sync Your Creations Across Your Devices
                </p>
                <p className={'home-txt m-nothing'}
                   style={{
                       textAlign: "left", width: '65%',
                       // fontSize: '0.8vw',
                       margin: "auto",
                       fontSize:'1.3rem'
                   }}>
                    Create your aesthetic - with an optimised aspect ratio, your creations can be turned into unique wallpapers and lock screen images for your phone.
                </p>
                <p className={'home-txt m-nothing'}
                   style={{
                       textAlign: "left",
                       width: '65%',
                       margin: "auto",
                       // fontSize: '0.8vw',
                       fontSize:'1.3rem',
                       padding: '3rem 0 4rem 0'
                   }}>
                    Whatever your style, you’ll be able to personalise your phone and give new life to your screen!
                </p>
            </Col>
            <Col className={'m-nothing'} offset={3} lg={6} style={{}}>
                <img src={img_phone} alt={'phone'}
                     className={'phone-img'}/>
            </Col>
            <Col  className={'m-nothing'} lg={6} style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img style={{width: '55%'}} src={qr} alt={'qr'}/>
                <p style={{margin: "0 0 2rem 0"}}>
                    Scan the QR code with your smartphone to download Vieutopia.
                </p>
                <Button className={'download-app-button'}
                        icon={<AppleOutlined/>}
                        onClick={() => window.open('https://apps.apple.com/gb/app/vieutopia/id1660264569')}
                >
                    DOWNLOAD IOS APP
                </Button>
                <Button className={'download-app-button'}
                        icon={<GoogleOutlined/>}
                        onClick={() => window.open('https://play.google.com/store/apps/details?id=com.app.vieutopia')}
                >
                    DOWNLOAD ANDROID APP
                </Button>
            </Col>
        </Row>
    );
}

export default PhoneSection;