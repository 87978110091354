import {SERVER} from "../../settings";
import {style_value} from "./text";

export const api_gen_img = (props) => {
    const {style, prompt, width, height, setProcess, setIsLimited, setIsBanned} = props
    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user_request: {
                device_type: 'web',
                firebase_token: 'string',
            },
            text_to_image_generate_image_item: {
                prompt: prompt,
                style: style_value[style],
                height: parseInt(height),
                width: parseInt(width),
            },
        }),
    };

    return fetch(SERVER + '/apis/text_to_image/generating_image', requestOptions)
        .then(async response => {
            if (!response.ok) {
                switch (response.status) {
                    case 429:
                        await setIsLimited(true)
                        break
                    case 451:
                        await setIsBanned(true)
                        break
                    default:
                        alert(`HTTP ERROR! Status: ${response.status}`)
                }
                throw new Error(`HTTP ERROR! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(async data => {
            await setProcess(true)
            if (data.task_id) {
                return data.task_id
            } else {
                throw new Error(`${data}`)
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });
}

const api_gen_img_final = (props) => {
    const {taskId} = props
    const requestOptions = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "task_id": taskId,
            "watermark": true
        }),
    };
    return fetch(SERVER + '/apis/text_to_image/access_final', requestOptions)
}

const POLL_INTERVAL = 5000; // 5 seconds

export const pollForResult = (props) => {
    const {taskId, setImgUrl, setProcess, setBlob} = props
    api_gen_img_final({taskId})
        .then(response => {
            if (response.status === 200) {
                console.log('Process completed');
                return response.blob();
            } else if (response.status === 202) {
                // Process still ongoing, poll again after delay
                console.log('Processing');
                setTimeout(() => pollForResult({
                    taskId: taskId,
                    setImgUrl: setImgUrl,
                    setProcess: setProcess,
                    setBlob: setBlob
                }), POLL_INTERVAL);
                throw 'waiting'
            } else {
                throw new Error('Unexpected status: ' + response.status);
            }
        })
        .then(async blob => {
            const imgUrl = URL.createObjectURL(blob);
            await setImgUrl(imgUrl);
            await setProcess(false)
            await setBlob(blob)
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

