import {Col, Modal, Row} from "antd";
import React from "react";
import './style.scss'
import {t2aImagePaths, i2iImagePaths} from './importImages'
import {i2iOrder} from "../../../ImgToImg/text";

const StyleModal = (props) => {
    let {isStyleOpen, setIsStyleOpen, form, mode, isI2I} = props
    const images = mode === 'i2i' ? i2iImagePaths : t2aImagePaths
    const sortedImages = images.sort((a, b) => {
        const styleA = a.split('/').pop().split('.')[0];
        const styleB = b.split('/').pop().split('.')[0];
        return i2iOrder[styleA] - i2iOrder[styleB];
    });
    return (
        <Modal className={'style-div'}
               open={isStyleOpen}
               onCancel={_ => setIsStyleOpen(false)}
        >
            <Col style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                marginLeft:isI2I?'1.4rem':'0'
            }}>
                <Row justify={isI2I ? "start" : "center"} style={isI2I ? {
                    rowGap: '0px',
                    columnGap: '0px'
                } : {
                    rowGap: '0px',
                    columnGap: '28px'
                }}>
                    {sortedImages.map((path) => {
                        const name = path.split('/')[3].split('.')[0]
                        return (
                            <div className={'style-single'}
                                 key={name}
                                 onClick={
                                     async _ => {
                                         await form.setFieldValue("style", name);
                                         await setIsStyleOpen(false)
                                     }
                                 }>
                                <img
                                    src={path}
                                    style={{
                                        width: isI2I?'120px':'90px',
                                        height: "auto"
                                    }}
                                />
                                <p style={{color: "white", marginTop: '0'}}>
                                    {name}
                                </p>
                            </div>
                        )
                    })}
                </Row>
            </Col>

        </Modal>
    )
}
export default StyleModal;