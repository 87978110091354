import React, {useState} from 'react';
import './style.scss'
import PhoneSection from "../Home/components/PhoneSection";
import Comments from "../Home/components/Comments";
import SocialGroup from "../Home/components/SocialGroup";
import {Button, Row} from "antd";
import howToVideo from "../../img/app/howto.mp4"


const HowTo = () => {
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };
    return (
        <div>
            <Row justify={"center"}
                 className={'bg-row'}
                 style={{
                     display: "flex",
                     flexDirection: "column",
                     justifyContent: 'center',
                     alignItems: 'center',
                 }}
            >
                <p style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: '2.8rem'
                }}>How To Use Vieutopia</p>
                <video
                       style={{width: '60%'}}
                       playsInline
                       loop autoPlay
                       // ref={makeMuted}
                       // muted
                       // rel={"preload"}
                    controls={true}
                >
                    <source src={howToVideo} type={'video/webm'}></source>
                </video>
                <button
                    className={'create-btn'}
                    onClick={
                        _ => window.location.href = '/ai-art-generator'
                    }>
                    <p className={'global-font'}
                    style={{fontWeight:500,
                    fontSize:'1.5rem'
                    }}
                    >START CREATING</p>
                </button>
            </Row>
            <PhoneSection/>
            <Comments/>
            <SocialGroup/>
        </div>
    )
}

export default HowTo;

