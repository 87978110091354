export const Text = [
    'Access all features and art styles without any additional cost, subscriptions, or bank details needed',
    'Create, view and manage your generated art by using our web platform or mobile app',
    'Unlike some other AI Art platforms, with Vieutopia you can generate an unlimited number of images',
    'Select from a diverse number of art styles for generated images and your uploaded images',
    'We frequently evaluate our banned words to ensure that no offensive images are generated ',
    'Coming soon - join with other Vieutopia users and share your generated art',
]

export const MText = [
    'Vieutopia will not request payment for subscription/credits from you or ask you to leave credit card details',
    'Create, view and manage your generated art using our web platform or mobile app',
    'Select from a diverse number of art styles for generated images and your uploaded images',
    'Unlike some other AI Art platforms, your Vieutopia account allows you to generate an unlimited number of images',
    'We frequently evaluate our banned words to ensure that no offensive images are generated',
    'Coming soon - join with other Vieutopia users and share your generated art',
]