import React, {useState, useRef, useEffect} from 'react';
import './style.scss';
import {ReactComponent as SliderIcon} from "./Slider Arrows.svg";


const SplitView = (props) => {
    const {img1, img2, setIsPreview} = props
    const [dividerPosition, setDividerPosition] = useState(50);
    const dragging = useRef(false);
    const containerRef = useRef(null);
    const [imgData, setImgData] = useState({
        width: 0,
        height: 0,
        ratio: 0,
    });

    useEffect(() => {
        // Load and get dimensions of img1
        const imgEle = new Image();
        imgEle.src = img1;
        imgEle.onload = () => {
            setImgData({
                width: imgEle.width,
                height: imgEle.height,
                ratio: imgEle.width / imgEle.height,
            });
        };
    }, [img1]);

    const handleDividerMouseDown = () => {
        dragging.current = true;
    };

    const handleDividerMouseUp = () => {
        dragging.current = false;
    };

    const handleDividerDrag = (e) => {
        if (dragging.current) {
            const container = containerRef.current;
            const containerRect = container.getBoundingClientRect();
            const newPosition = ((e.clientX - containerRect.left) / containerRect.width) * 100;

            if (newPosition >= (imgData.width > imgData.height ? 0 : 15)
                && newPosition <= (imgData.width > imgData.height ? 100 : 85)) {
                setDividerPosition(newPosition);
            }
        }
    };

    return (
        <div
            className="split-container"
            ref={containerRef}
            onMouseMove={handleDividerDrag}
            onMouseUp={handleDividerMouseUp}
        >
            <div
                className="split-image"
                style={{
                    clipPath: `inset(0% ${100 - dividerPosition}% 0% 0%)`,
                }}
            >
                <img src={img1} alt="Left"/>
            </div>
            <SliderIcon
                className="sp-divider slider-icon"
                onMouseDown={handleDividerMouseDown}
                style={{
                    left: `calc(${dividerPosition}% - 2px)`,
                    cursor: 'pointer',
                }}
            />
            <div
                className="split-image"
                style={{
                    clipPath: `inset(0% 0% 0% ${dividerPosition}%)`,
                }}
            >
                <img src={img2} onClick={() => {
                    setIsPreview(true)
                }}
                     style={{cursor: "pointer"}}
                     alt="Right"/>
            </div>
        </div>
    );
};

export default SplitView;
