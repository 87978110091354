export const capitalizeFirst = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const logout = () => {
    localStorage.clear()
    window.location.href = '/home'
}

export const getRandomString = (length) => {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomString = "";
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset[randomIndex];
    }
    return randomString;
}