import React, {useState} from 'react';
import {Button, Row} from "antd";
import {api_guest_login} from "./service";


const Landing = (props) => {
    const {setCurStep} = props
    return (
        <div>
            <p className={'global-font'}
                style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                fontSize: '1.6rem',
                marginTop: '0',
                fontWeight:"bold",
            }}>
                Welcome to Vieutopia
            </p>
            <p className={'global-font'}
                style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                marginTop: '15px',
                    fontSize:'1rem',
                    fontWeight:'600',
                    fontStyle:"normal",
            }}>
                Log in with your Vieutopia account to access full features, or create a free account.
            </p>
            <p className={'global-font'}
                style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                margin: '-10px 0 0 0',
                    fontSize:'1rem',
                    fontWeight:'600',

            }}>
                Alternatively, join as a guest for a limited experience.
            </p>
            <Row justify={"center"} className={'login-btn-row'} style={{
                columnGap: '4rem',
                marginTop: '4rem'
            }}>
                <Button className={'login-btn'}
                        onClick={_ => {
                            setCurStep(2)
                        }}
                >
                    Log in
                </Button>
                <Button className={'login-btn'}
                        onClick={_ => {
                            setCurStep(1)
                        }}
                >
                    Sign up
                </Button>
            </Row>
            <p style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                fontSize: '1.6rem',
                textDecoration: 'underline',
                cursor: "pointer"
            }}
               onClick={() => {
                   api_guest_login()
               }}
            >
                Join as guest
            </p>
        </div>
    )
}

export default Landing;