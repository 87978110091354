import React, {useState} from 'react';
import {Col, Row} from "antd";
import textura_img from "../../../../img/home/Textura Image.webp"
import m_textura_img from "../../../../img/home/Display Your Canvas.webp"
import './style.scss'

const Display = () => {
    return (
        <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
            <p className={'m-sm-title'}>
                Display your creations on Textura digital canvas
            </p>
            <p className={'home-txt'}>
                You can share the unique artwork that you create on your social media timeline and use it as a wallpaper
                for your phone or tablet, but there is another dynamic new medium for showcasing your masterpieces – the
                Vieunite Textura digital canvas.
            </p>
            <img className={'m-nothing'} src={textura_img} alt={'textura_img'}/>
            <img className={'dt-nothing'}
                 style={{margin:'-16rem 0 0 0'}}
                 src={m_textura_img}
                 alt={'m-textura-img'}/>
            <Row justify={"center"} style={{
                backgroundColor: "#CFCFCF",
                padding: '2rem 0 4rem 0'
            }}>
                <p className={'m-sm-title m-mg-display'} style={{width: '100%'}}>
                    Vieutopia supports artists
                </p>
                <p className={'home-txt m-margin'}>
                    Vieutopia is much more than just another AI art generator. Created by Vieunite, a brand that is
                    dedicated to serving the art community and the digital art movement, Vieutopia not only allows users
                    to creatively express themselves but does so in a way that is respectful to the artists. <span
                    className={'dt-nothing'}>
                    If you are an artist that would like your work, style or name to be disavowed from our platform
                    please <span style={{
                    cursor: "pointer",
                    textDecoration: "underline"
                }} onClick={() => {
                    window.location.href = `mailto:info@vieutopia.com`;
                }}>get in touch </span>with us to express your feelings.
                </span>
                </p>
                <p className={'home-txt m-nothing'}
                >
                    If you are an artist that would like your work, style or name to be disavowed from our platform
                    please <span style={{
                    cursor: "pointer",
                    textDecoration: "underline"
                }} onClick={() => {
                    window.location.href = `mailto:info@vieutopia.com`;
                }}>get in touch </span>with us to express your feelings.
                </p>
            </Row>
        </div>
    );
}

export default Display;