import React, {useRef, useState} from 'react';
import './style.scss'
import {Button, Col, Form, Input, Upload} from "antd";
import ImgCrop from "antd-img-crop";
import avatar from '../../../img/app/avatar.jpeg'
import {UpTEXT} from "./text";
import {api_save_profile, api_upload_avatar} from "./service";

const AccountModal = (props) => {
    const {userInfo} = props
    const [form] = Form.useForm()
    const cropperRef = useRef(null)
    const [avatarUrl, setAvatarUrl] = useState(userInfo.avatar_path ?
        userInfo.avatar_path : avatar)

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            if (file) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    // Extract the Base64-encoded image data
                    const base64String = e.target.result.split(',')[1];
                    resolve(base64String);
                };

                reader.onerror = (error) => {
                    reject(error);
                };

                reader.readAsDataURL(file);
            } else {
                reject(new Error("File not provided"));
            }
        });
    };

    return (
        <Col span={24} className={'acc-col'}
        style={{marginTop:'2rem'}}
        >
            <p className={'acc-txt global-font'}
               style={{fontSize: '2rem',fontWeight:600}}
            >EDIT PROFILE</p>
            <p className={'acc-txt global-font'}
               style={{marginTop: '0%',fontSize:'1.2rem',fontWeight:'600'}}
            >Your name and avatar appear on your Vieutopia profile.</p>
            <p className={'acc-txt global-font'}
               style={{marginTop: '-1%',marginBottom:'2%',fontSize:'1.2rem',fontWeight:'600' }}
            >Your email address remains private.</p>
            <Form
                form={form}
                onFinish={(values) => {
                    const name = values.name
                    api_save_profile(name, avatarUrl)
                }}
                style={{
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: "center",
                    alignItems: "center"
                }}
                initialValues={{name: userInfo.display_name}}
            >
                <Form.Item name='name'
                           rules={[
                               {
                                   required: true,
                                   message: 'Please set a Nick Name!',
                               },
                           ]}
                >
                    <Input className={'name-input'}
                           placeholder="Nick Name"
                    />
                </Form.Item>
                <div style={{
                    margin: '1rem 0 1rem 0',
                    height: "20vw",
                    width: "20vw",
                }}>
                    <img
                        alt={'avatar'}
                        style={{
                            width: "20vw",
                            height: "20vw",
                            objectFit: 'cover',
                        }}
                        src={avatarUrl}/>
                </div>
                <ImgCrop
                    ref={cropperRef}
                    rotationSlider={false}
                    modalClassName={'crop-md'}
                    modalTitle={<>
                        <h2 className="title-app global-font"
                            style={{
                                textAlign: "center",
                                fontSize: '2rem',
                                fontWeight: '500',
                                margin: '0 0 0 0'
                            }}>
                            UPLOAD
                        </h2>
                        <p  className={'global-font'} style={{
                            fontSize: '0.8rem',
                            fontWeight: '400',
                            textAlign: "center"
                        }}>{UpTEXT}
                        </p>
                    </>}
                    modalOk={'CONFIRM'}
                    modalCancel={'CANCEL'}
                >
                    <Upload
                        accept={'.png,.jpg,.jpeg,.webp'}
                        className={'sp-btn'}
                        listType={null}
                        showUploadList={false}
                        name="avatar"
                        maxCount={1} //限制上传数量。当为 1 时，始终用最新上传的文件代替当前文件
                        multiple={false} //是否支持多选文件，开启后按住 ctrl 可选择多个文件
                        customRequest={async ({file}) => {
                            try {
                                const bs64 = await getBase64(file);
                                await api_upload_avatar(bs64, setAvatarUrl)
                            } catch (error) {
                                console.error("Error converting image to Base64:", error);
                            }
                        }}
                    >
                        <Button className={'up-avatar-btn global-font'}
                        style={{fontSize:'1.25rem',fontWeight:'600',
                            margin:'1.1rem 0 0 0'
                        }}
                        >
                            UPDATE PROFILE PICTURE
                        </Button>
                    </Upload>
                </ImgCrop>
                <Button
                    htmlType={"submit"}
                    className={'save-profile-btn global-font'}
                    style={{fontSize:'1.25rem',
                        fontWeight:'600',
                        margin:'1.5rem 0 4rem 0'}}
                >
                    SAVE CHANGES
                </Button>
            </Form>
        </Col>
    )
}

export default AccountModal;