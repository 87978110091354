import {SERVER} from "../../settings";

export const api_upload_image = (imageEncoded) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "image_encoded": imageEncoded
        }),
    };

    return fetch(SERVER + '/apis/pix2pix/upload_image', requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Handle the response data here
            return data.image_id
        })
        .catch(error => {
            console.error('Error:', error);
        });
};

export const api_access_final = (taskId) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            "task_id": taskId,
            "watermark": true
        }),
    };

    return fetch(SERVER + '/apis/pix2pix/access_final', requestOptions)
};

export const api_generate_image = async (genImgProps,setProcess,setIsLimited) => {
    const requestOptions = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Authorization': localStorage.getItem('Authorization'),
            // 'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJ5YW5nLnpob3VAYWxsc2VlLXRlY2guY29tIiwiZXhwIjoxNzAwMjIxNjM0fQ.dHe-PfKaU7Ab9zLSjclHhWjl4tcGnyPovWvIkno38aQ',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(genImgProps),
    };

    return fetch(SERVER + '/apis/pix2pix/generating_image', requestOptions)
        .then(async response => {
            if (!response.ok) {
                if (response.status === 429) {
                    await setIsLimited(true)
                }
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            // Handle the response data here
            setProcess(true)
            return data.task_id
        })
        .catch(error => {
            console.error('Error:', error);
        });
};
const POLL_INTERVAL = 5000; // 5 seconds

export const i2iPollForResult = (props) => {
    const {taskId, setImgUrl, setProcess, setBlob} = props
    api_access_final(taskId)
        .then(response => {
            if (response.status === 200) {
                console.log('Process completed');
                return response.blob();
            } else if (response.status === 202) {
                // Process still ongoing, poll again after delay
                setTimeout(() => i2iPollForResult({
                    taskId: taskId,
                    setImgUrl: setImgUrl,
                    setProcess: setProcess,
                    setBlob: setBlob
                }), POLL_INTERVAL);
                throw 'waiting'
            } else {
                throw new Error('Unexpected status: ' + response.status);
            }
        })
        .then(async blob => {
            const imgUrl = URL.createObjectURL(blob);
            await setImgUrl(imgUrl);
            await setProcess(false)
            await setBlob(blob)
        })
        .catch(error => {
            console.error('Error:', error);
        });
};