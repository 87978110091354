import {SERVER} from "../../../../settings";

export const api_isRegisterd = (props) => {
    const {email} = props
    const requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };

    return fetch(SERVER + `/users/is_registered?email=${email}`, requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(result => {
            return result['is_registered']
        })
        .catch(error => console.log('error', error));
}

