import React, {useState} from 'react';
import './style.scss'
import {Button, Col, Row} from "antd";
import LoginFooter from "./components/LoginFooter";
import {ReactComponent as Logo} from "../../img/header/Vieutopia Logo White.svg";
import Landing from "./components/Landing";
import SignUp from "./components/SignUp";
import LoginEmail from "./components/LoginEmail";
import LoginPassword from "./components/LoginPassword";
import ResetPassword from "./components/ResetPassword";

const Login = () => {
    const [curStep, setCurStep] = useState(0)
    const [email, setEmail] = useState('')
    const steps = {
        0: <Landing setCurStep={setCurStep}/>,
        1: <SignUp setCurStep={setCurStep}/>,
        2: <LoginEmail setCurStep={setCurStep} setEmail={setEmail}/>,
        3: <LoginPassword setCurStep={setCurStep} email={email}/>,
        4: <ResetPassword setCurStep={setCurStep} email={email}/>
    }
    return (
        <div className={'login-bg'}>
            <Row justify={"center"} style={{
                paddingTop: '5%'
            }}>
                <Col span={'14'}
                     className={'login-col'}
                >
                    <Row justify={"center"}>
                        <Logo style={{
                            fontSize: '1rem',
                            width: '16rem'
                        }}/>
                    </Row>
                    {steps[curStep]}
                </Col>
            </Row>
            <LoginFooter/>
        </div>
    )
}

export default Login;