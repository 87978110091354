import React from "react";
import {Helmet} from "react-helmet";

export const HomepageMeta = () => (
    <Helmet>
        <title>Vieutopia – Your Free AI Art Generator | Create AI Images and Personalise Photos</title>
        <meta name="description"
              content="Unleash your creativity, generate stunning art, and transform your ideas into reality with Vieutopia's AI-powered art creation tool, all without any subscription fees."/>
        <meta name="keywords"
              content="ai, ai art, ai art maker, ai art generator, digital art, ai drawing, ai filter, photo ai, ai avatar, gallery, art gallery, text to art, image to image ai, AI image, AI artwork, AI art creation, Neural network art, AI-powered design, Machine-generated art, Digital painting, Computer-generated graphics, Creative AI, AI-based illustrations, Artistic algorithms, AI artistry, Virtual gallery, Generative art, AI design tools, Artistic technology, AI-enhanced images, AI photo effects, AI portrait, AI-generated landscapes, AI image manipulation"/>
        <meta name="twitter:card" value="summary_large_image"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieutopia | Official Site"/>
        <meta name="twitter:description"
              value="Unleash your creativity, generate stunning art, and transform your ideas into reality with Vieutopia's AI-powered art creation tool, all without any subscription fees."/>
        <meta name="twitter:url" value="https://vieutopia.com"/>
        <meta name="twitter:image" value={'https://vieutopia.com/Vieutopia - Social Card.webp'}/>
        <meta property="og:url" content="https://vieutopia.com/"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieutopia | Official Site"/>
        <meta name="description" property="og:description"
              content="Unleash your creativity, generate stunning art, and transform your ideas into reality with Vieutopia's AI-powered art creation tool, all without any subscription fees."/>
        <meta name="image" property="og:image" content="https://vieutopia.com/Vieutopia - Social Card.webp"/>
        <meta property="og:image:width" content="930"/>
        <meta property="og:image:height" content="620"/>
    </Helmet>);