import React, {useState} from 'react';
import './style.scss'
import {Button, Col, Modal} from "antd";

const text =
    'You’ve reached your daily limit of 3 generated images! But don’t worry, simply Sign Up and gain free unlimited image creation access!'
const LimitModal = (props) => {
    const {isLimited, setIsLimited} = props
    return (
        <Modal
            className={'limit-div'}
            open={isLimited}
            onOk={_ => {
            }}
            onCancel={_ => setIsLimited(false)}
            footer={[ <Button
                key="link"
                type="primary"
                onClick={_=>
                    window.location.href = '/login'
                }
                className={'limit-signup-btn'}
            >
                <span className={'popup-font'} style={{
                    fontWeight:600,
                    fontSize:'1.5rem'
                }}>Sign Up</span>
            </Button>,]}
        >
            <Col
                span={24}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <p className={'popup-font'} style={{
                    width:'80%',
                    textAlign:"center",
                    fontSize:'1.5rem',
                    color:"black",
                    fontWeight:'600'
                }}>{text}</p>
            </Col>
        </Modal>
    )
}

export default LimitModal