import {capitalizeFirst, getRandomString} from "../../../utils";
import {SERVER} from "../../../../settings";

export const api_guest_login = async () => {
    const name = getRandomString(8)
    const password = 'Yang'
    const data = new URLSearchParams();
    data.append('username', name);
    data.append('password', password);

    const requestOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: data,
    };

    fetch(SERVER + '/users/login/guest', requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Wrong information!`);
            }
            return response.json();
        })
        .then(async data => {
            const Authorization = capitalizeFirst(data.token_type) + ' ' + data.access_token
            await localStorage.setItem('Authorization', Authorization)
            await localStorage.setItem('isGuest', 'true')
            window.location.href = '/ai-art-generator'
        })
        .catch(error => console.error('Error:', error));
}