import React, {useState} from 'react';
import {Col, Row} from "antd";
import Slider from "react-slick";
import {StarFilled} from "@ant-design/icons";
import {commentText} from "./text";
import './style.scss'

const Comments = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        // slidesToScroll: 9,
        autoplay: false,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear",
    };
    return (
        <div className={'m-pad-cm'} style={{backgroundColor: "black", padding: '0.5rem 0 0 0'}}
        >
            <p className={'m-sm-title m-special-cm'}
                style={{
                    width:'100%',
                    color:"white"
            }}>
                What Our Users Are Saying
            </p>
            {commentText.map((txt, idx) =>
                <Col className={'dt-nothing'} key={idx} xs={20}
                     style={{
                     }}
                >
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        margin: "1rem auto 1rem 1.6rem"
                    }}>
                        {[...Array(5)].map(() =>
                            <StarFilled style={{fontSize: '1em',
                                color: "yellow",
                                textAlign:"left"
                            }}
                            />)}
                    </div>
                    <p className={'comment-txt global-font'} style={{
                        textAlign: "left",
                        margin: "1rem auto 1rem auto",
                        color: "white",
                        padding:'0 1.5rem 0 1.5rem',
                        fontSize:'1rem',
                        fontWeight:'500',
                        fontFamily:'ManifoldCF-Medium'
                    }}>
                        {txt.comment}
                    </p>
                    <p style={{
                        textAlign: "left",
                        margin: "1rem auto 4rem 1.5rem",
                        color: "white",
                        fontSize:'1rem',
                        fontWeight:'700',
                        fontFamily:'ManifoldCF-Medium',
                    }}>
                        {txt.name}
                    </p>
                </Col>)}
            <Slider {...settings}
                    style={{margin: '1rem 0 0 0'}}
                    className={'m-nothing'}
            >
                {commentText.map((txt, idx) =>
                    <div key={idx}>
                        <p style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "1rem auto 1rem auto", color: "white"
                        }}>{txt.title}</p>
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "1rem auto 1rem auto"
                        }}>
                            {[...Array(5)].map(() =>
                                <StarFilled style={{fontSize: '1.8em', color: "yellow"}}
                                />)}
                        </div>
                        <p style={{
                            textAlign: "center",
                            margin: "1rem auto 1rem auto",
                            color: "white",
                            padding:'0 1.5rem 0 1.5rem',
                            fontSize:'1rem',
                            fontWeight:'500'
                        }}>
                            {txt.comment}
                        </p>
                        <p style={{
                            textAlign: "center",
                            margin: "1rem auto 4rem auto", color: "white"
                        }}>{txt.name}</p>
                    </div>
                )}
            </Slider>
        </div>
    );
}

export default Comments;