import {capitalizeFirst} from "../../../utils";
import {SERVER} from "../../../../settings";

export const api_login = (props) => {
    const {email, password, setIsReminderOpen} = props
    const formdata = new FormData();
    formdata.append("username", email);
    formdata.append("password", password);

    const requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };

    return fetch(SERVER + "/users/login", requestOptions)
        .then(response => {
            if (!response.ok) {
                throw new Error(`Wrong information!`);
                // setIsReminderOpen(true)
            }
            return response.json();
        })
        .then(async result => {
            const Authorization = capitalizeFirst(result.token_type) + ' ' + result.access_token
            await localStorage.setItem('Authorization', Authorization)
            await localStorage.removeItem('isGuest')
            window.location.href = '/ai-art-generator'
        })
        .catch(error => alert(error));
}