import React, {useState} from 'react';
import {Button, Col, Row} from "antd";
import './style.scss'
import bg_vd from "../../../../img/home/Home Page New Scroller.mp4";
import FullScreenBgVideo from "../../../components/BgVideo/FullScreenBgVideo";

const VideoBg = () => {
    return (
        <>
            <FullScreenBgVideo url={bg_vd}/>
            <Col lg={10} className={'over-section'}>
                <p className={'imagination-font'}
                    style={{
                    // color: "white",
                    // fontSize: '4vw',
                    margin: "auto",
                }}>
                    Your imagination
                </p>
                <p className={'unleashed-font'}
                    style={{
                    // color: "white",
                    // fontSize: '6vw',
                    // fontWeight: "bold",
                    margin: "auto",
                }}>
                    unleashed
                </p>
                <Button
                    className={'start-btn'}
                    onClick={
                        _ => window.open('/ai-art-generator', "_self")
                    }
                >
                    GET STARTED
                </Button>
                <p className={'small-btn'}
                   onClick={_ =>
                       window.open('/how-to-use-vieutopia', "_self")
                   }
                >
                    How to use Vieutopia
                </p>
                <p className={'small-btn'} onClick={_=>{
                    window.open('https://apps.apple.com/gb/app/vieutopia-ai-art-generator/id1660264569')
                }}>
                    Download the mobile app
                </p>
            </Col>
        </>
    );
}

export default VideoBg;