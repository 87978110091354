import {Button, Col, Modal} from "antd";
import React from "react";
import './style.scss'

const RemindModal = (props) => {
    const {isReminderOpen, setIsReminderOpen, text} = props
    return (
        <Modal
            className={'reminder-div'}
            open={isReminderOpen}
            onOk={_ => {
            }}
            // onCancel={_ => setIsLimited(false)}
            footer={[ <Button
                key="link"
                type="default"
                onClick={_=>
                    setIsReminderOpen(false)
                }
                className={'limit-signup-btn'}
            >
                <span style={{color:"white"}}>OK</span>
            </Button>,]}
        >
            <Col
                span={24}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom:'-50px'
                }}
            >
                <p className={'reminder-txt'}>{text}</p>
            </Col>
        </Modal>
    )
}

export default RemindModal;