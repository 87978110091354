import React, {useState} from 'react';
import {Col, Row} from "antd";
import './style.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import art_1 from "../../../../img/home/StyleCarousel/Abstract.webp"
import art_2 from "../../../../img/home/StyleCarousel/3D Render.webp"
import art_3 from "../../../../img/home/StyleCarousel/Afrofuturism.webp"
import art_4 from "../../../../img/home/StyleCarousel/Anime.webp"
import art_5 from "../../../../img/home/StyleCarousel/Analogue.webp"
import art_6 from "../../../../img/home/StyleCarousel/Bell Jar.webp"
import art_7 from "../../../../img/home/StyleCarousel/Cinematography.webp"
import art_8 from "../../../../img/home/StyleCarousel/Cyberpunk.webp"
import art_9 from "../../../../img/home/StyleCarousel/Pop Art.webp"

const arts = [[art_1, 'Abstract'],
    [art_2, '3D Render'],
    [art_3, 'Afrofuturism'],
    [art_4, 'Anime'],
    [art_5, 'Analogue'],
    [art_6, 'Bell Jar'],
    [art_7, 'Cinematography'],
    [art_8, 'Cyberpunk'],
    [art_9, 'Pop Art']]

const StyleCarousel = () => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        // slidesToScroll: 9,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    const mSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        // slidesToScroll: 9,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 2000,
        cssEase: "linear"
    }
    return (
        <>
            <p className={'m-title'} style={{}}>
                Diverse art styles
            </p>
            <Slider {...settings} style={{margin: '4rem 0 4rem 0'}} className={'m-nothing'}>
                {arts.map((art, idx) =>
                    <div key={idx}>
                        <img className={'carousel-art'} alt={idx.toString()} src={art[0]}/>
                        <p style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "auto"
                        }}>
                            {art[1]}
                        </p>
                    </div>
                )}
            </Slider>
            <Slider {...mSettings} style={{margin: '10rem 0 4rem 0'}} className={'dt-nothing'}>
                {arts.map((art, idx) =>
                    <div key={idx}>
                        <img className={'carousel-art'} alt={idx.toString()} src={art[0]}/>
                        <p style={{
                            textAlign: "center",
                            fontWeight: "bold",
                            margin: "auto"
                        }}>
                            {art[1]}
                        </p>
                    </div>
                )}
            </Slider>
        </>
    );
}

export default StyleCarousel;