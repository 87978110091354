import React, {useState} from 'react';
import './style.scss'
import {Button, Form, Input} from "antd";
import {api_forgot_ps} from "./service";
import RemindModal from "../../../TextToArt/components/RemindModal";
const ResetPassword = (props) => {
    const {setCurStep, email} = props
    const [form] = Form.useForm()
    const [remindOpen, setRemindOpen] = useState(false)
    return (
        <div className={'lg-password-div'}
             style={{
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "center",
                 alignItems: 'center',
             }}
        >
            <p style={{
                color:"white",
                fontSize:'1.6rem',
                margin:'0 0 0 0'
            }}>Reset Your Password</p>
            <Form
                form={form}
                name="resetform"
                style={{
                    maxWidth: 600,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                initialValues={{
                    email: email,
                }}
                onFinish={async (values) => {
                    api_forgot_ps(values)
                }}
                autoComplete="off"

            >
                <Form.Item
                    name="email"
                >
                    <Input className={'signup-form'}
                           placeholder={'Email'}
                           style={{color: 'white', zIndex: ''}}
                           disabled={true}
                    />
                </Form.Item>

                <Form.Item
                    name="newPassword"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your new password!',
                        },
                    ]}
                >
                    <Input.Password
                        className={'signup-form password-signup-form'}
                        placeholder={'New Password'}
                        // style={{background:'white'}}
                    />
                </Form.Item>
                <Button className={'lg-password-ct-btn'}
                        htmlType={"submit"}
                >
                    Reset
                </Button>
            </Form>
            <RemindModal/>
        </div>
    )
}

export default ResetPassword