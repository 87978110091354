import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import BasicLayout from "./layout/BasicLayout";
import Home from "./pages/Home";
import AI from "./app/AI";
import HowTo from "./pages/HowTo";
import QA from "./pages/QA";
import Login from "./app/Login";
import TextToArt from "./app/TextToArt";
import Contact from "./pages/Contact";
import About from "./pages/About";
import ImgToImg from "./app/ImgToImg";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/contact'} element={<Contact/>}/>
                <Route path="/" element={<BasicLayout/>}>
                    <Route index={true} element={<Home/>}/>
                    <Route path="home" element={<Home/>}/>
                    <Route path={'about'} element={<About/>}/>
                    <Route path="index.html" element={<Home/>}/>
                    <Route path={"ai-art-generator"} element={<AI/>}/>
                    <Route path={'how-to-use-vieutopia'} element={<HowTo/>}/>
                    <Route path={'qa'} element={<QA/>}/>
                    <Route path={'text-to-art'} element={<TextToArt/>}/>
                    <Route path={'photo-to-art'} element={<ImgToImg/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
