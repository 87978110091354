export const T2ATitle = "Enter your text prompt and choose from a diverse range of art styles developed with the characteristics of your favourite artists and art movement";
export const RatioLists = {
    'LD': ['1024 x 576', '768 x 576', '720 x 576', '576 x 576', '576 x 1024', '576 x 768', '576 x 720',],
    'MD': ['1280 x 720', '960 x 720', '900 x 720', '720 x 720', '720 x 1280', '720 x 960', '720 x 900',],
    'HD': ['1920 x 1080', '1440 x 1080', '1350 x 1080', '1080 x 1080', '1080 x 1920', '1080 x 1440', '1080 x 1350',],
    'UHD': ['3840 x 2160', '2880 x 2160', '2700 x 2160', '2160 x 2160','2160 x 3840', '2160 x 2880', '2160 x 2700', ],
}

export const style_value = {
    "Abstract": "abstract",
    "Afrofuturism": "afrofuturism",
    "Ancient": "ancient",
    "Anime": "anime",
    "Baroque": "baroque",
    "Bell Jar": "belljar",
    "Cinematography": "cinematography",
    "Classic": "classic",
    "Colour and Line": "colourandline",
    "Cubist": "cubist",
    "Cyberpunk": "cyberpunk",
    "Expressive": "expressive",
    "Fantasy": "fantasy",
    "Impressionist": "impressionist",
    "Intuitive": "random",
    "Kind of Blue": "kindofblue",
    "Lomography": "lomography",
    "Minimalism": "minimalism",
    "Neon Arts": "neonarts",
    "Pop Art": "popart",
    "Realism": "realism",
    "Shanshui": "shanshui",
    "Sketch": "sketch",
    "Steampunk": "steampunk",
    "Strange": "strange",
    "Studio Light": "studiolight",
    "Watercolour": "watercolour",
    "3D Render": "3drender"
}