import React, {useState} from 'react';
import './style.scss'
import {Col, Dropdown, Empty, Menu, Row} from "antd";
import {ReactComponent as Logo} from "../../../img/header/Vieutopia Logo Black.svg";
import MobileMenu from "../MobileMenu/MobileMenu";

const Header = () => {
    return (
        <div>
            <div>
                <div className={'m-header dt-nothing '}
                     style={{}}
                >
                    {/*<div className={'logo'} style={{background: "black"}}>*/}
                    {/*    <a href="/home">*/}
                    {/*        <Logo style={{*/}
                    {/*            filter: 'invert(100%)',*/}
                    {/*            margin: '0 0 0 0',*/}
                    {/*            width: '40vw'*/}
                    {/*        }}/>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    <MobileMenu/>
                </div>
            </div>
            <div className={'header m-nothing'}
                 style={{
                     display: "flex",
                     justifyContent: "space-between",
                     backgroundColor: "black"
                 }}
            >
                <div className={'logo'}>
                    <a href="/home">
                        <Logo style={{filter: 'invert(100%)', margin: '0 0 0 6rem'}}/>
                    </a>
                </div>
                <div className={'header-dt-menu'}>
                    <ul style={{
                        columnGap: '3rem',
                        display: "flex",
                        margin: '2.8rem 6rem 0 0'
                    }}
                    >
                        <li>
                            <a className={'global-font'} href="/home"
                               style={{
                                   color: "white",
                                   fontWeight: '500',
                                   fontSize: '1.5rem',
                                   textDecoration: "none"
                               }}>
                                HOME
                            </a>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="/news"*/}
                        {/*       style={{*/}
                        {/*           color: "white",*/}
                        {/*           fontWeight: '600',*/}
                        {/*           fontSize: '1rem',*/}
                        {/*           textDecoration: "none"*/}
                        {/*       }}>*/}
                        {/*        NEWS*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                        <li>
                            <a href="/about"
                               style={{
                                   color: "white",
                                   fontWeight: '500',
                                   fontSize: '1.5rem',
                                   textDecoration: "none"
                               }}>
                                ABOUT
                            </a>
                        </li>
                        <li>
                            <a href="/contact"
                               style={{
                                   color: "white",
                                   fontWeight: '500',
                                   fontSize: '1.5rem',
                                   textDecoration: "none"
                               }}>
                                CONTACT
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    );
}

export default Header;